import { Typography, Box } from '@material-ui/core';
import React, { Component } from 'react';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import Accessor from '__Static/Accessor';
//import PropsType from 'prop-types';

import HKULogo from "../Images/Logo - HKU MED.svg";

class Entrance extends Component {

  static propTypes = {

  };

  static defaultProps = {

  };

  constructor(props) {
    super(props);
    this.props.pauseAudio();
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Entrance.defaultProps))) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  nextPage() {
    this.props.changePageStatus("QR");
  }

  render() {
    return (
      <div class="portal">
        <div class="HKUBackground">
          <VStack width="100vw" justifyContent="center">
            <Box className="HKULogo">
              <img src={HKULogo} />
            </Box>
            <Typography className="entranceText">
              {"上肢功能受損程度問卷"}
            </Typography>
          </VStack>
        </div>
        <div class="HKUButton">
          <button class="buttonReset HKUNextButton" onClick={this.nextPage.bind(this)}></button>
        </div>
      </div>
    );
  }

}

export default Entrance;
