import React, { Component } from "react";
import Accessor from "__Static/Accessor";
import PropsType from "prop-types";

import store from "__Static/AppStore";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import { Paper, Typography } from "@material-ui/core";
import ColorX from "__Static/ColorX";
import StyledIconButton from "_Labizo/Stylizo/StyledIconButton";
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import { observer } from "mobx-react";
import { StyledButton } from "_Labizo/Stylizo";
import Redirect from "__Static/Redirect";
import "./ProductCard.css";

class ProductCard extends Component {
  static propTypes = {
    doc: PropsType.object,
  };

  static defaultProps = {
    doc: {},
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(ProductCard.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  renderAddCart() {
    let { doc } = this.props;
    let stock = doc.stock;
    let qtyInCart = store.getQty(doc._id);

    let addToCart = {
      EN: "Add to Cart",
      TC: "加入購物車",
    };

    let noStock = {
      EN: "Sold Out",
      TC: "售罄",
    };

    if (stock - qtyInCart <= 0) {
      return (
        <StyledButton
          theme={{
            width: "100%",
            background: ColorX.GetColorCSS("greyOut"),
            color: ColorX.GetColorCSS("pureWhite"),
          }}
          disabled
        >
          {noStock[store.lang]}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton
          theme={{
            width: "100%",
            background: ColorX.GetColorCSS("elainOrange"),
            color: ColorX.GetColorCSS("pureWhite"),
            hover: {
              background: ColorX.GetColorCSS("elainOrangeDark"),
            },
          }}
          onClick={() => {
            store.chat3D.Send("CMD_ADDCART");
            store.setInCart(doc._id, 1);
          }}
        >
          {addToCart[store.lang]}
        </StyledButton>
      );
    }
  }

  renderOldPrice() {
    let { doc } = this.props;
    if (doc.prev_price && doc.prev_price !== doc.price) {
      return (
        <HStack>
          <Typography
            style={{
              color: ColorX.GetColorCSS("greyOut"),
              fontSize: 16,
              textDecoration: "line-through",
              minHeight: 24,
            }}
          >
            {"HKD$ " + doc.prev_price}
          </Typography>
          <Spacer />
        </HStack>
      );
    } else {
      return (
        <HStack>
          <Typography
            style={{
              color: ColorX.GetColorCSS("greyOut"),
              fontSize: 16,
              minHeight: 24,
            }}
          >
            {""}
          </Typography>
          <Spacer />
        </HStack>
      );
    }
  }

  renderPrice() {
    let { doc } = this.props;
    return (
      <VStack>
        {this.renderOldPrice()}
        <HStack>
          <Typography style={{ color: ColorX.GetColorCSS("red"), fontSize: 20, fontWeight: "bold" }}>{"HKD$ " + doc.price}</Typography>
          <Spacer />
        </HStack>
      </VStack>
    );
  }

  renderFavourite() {
    let { doc } = this.props;
    if (store.isFavourite(doc._id)) {
      return (
        <StyledIconButton
          style={{ color: "red" }}
          size="small"
          onClick={() => {
            store.setFavourite(doc._id);
          }}
        >
          <Favorite />
        </StyledIconButton>
      );
    } else {
      return (
        <StyledIconButton
          size="small"
          onClick={() => {
            store.setFavourite(doc._id);
          }}
        >
          <FavoriteBorderOutlined />
        </StyledIconButton>
      );
    }
  }

  renderName() {
    let { doc } = this.props;
    return (
      <HStack>
        <Typography style={{ height: 24 }}>{doc.name[store.lang]}</Typography>
        <Spacer />
        {this.renderFavourite()}
      </HStack>
    );
  }

  renderBrand() {
    let { doc } = this.props;
    return (
      <HStack>
        <Typography
          style={{
            fontSize: 12,
            color: ColorX.GetColorCSS("greyOut"),
          }}
        >
          {store.getBrandName(doc.brand)}
        </Typography>
        <Spacer />
      </HStack>
    );
  }

  renderImage() {
    let { doc } = this.props;
    return (
      <HStack justifyContent="center">
        <HStack
          style={{
            background: ColorX.GetColorCSS("grey", 0.1),
            width: 200,
            height: 200,
            padding: 5,
          }}
        >
          <StyledButton
            onClick={() => {
              Redirect.toProduct(doc._id);
            }}
          >
            <HStack style={{ width: 200, height: 200 }}>
              {doc.images[0].startsWith("http") ? (
                <img src={doc.images[0]} alt={doc.name[store.lang]} style={{ objectFit: "contain" }} />
              ) : (
                <img src={"Images/Products/" + doc.images[0]} alt={doc.name[store.lang]} style={{ objectFit: "contain" }} />
              )}
            </HStack>
          </StyledButton>
        </HStack>
      </HStack>
    );
  }

  render() {
    let { doc } = this.props;
    if (!doc) return <div />;
    return (
      <Paper width={200} elevation={3} style={{ padding: 10, marginBottom: 10 }} className={"product-card"}>
        {this.renderImage()}
        {this.renderName()}
        {this.renderBrand()}
        {this.renderPrice()}
        {this.renderAddCart()}
      </Paper>
    );
  }
}

export default observer(ProductCard);
