import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Accessor from '__Static/Accessor';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';

import store from '__Static/AppStore';
import ColorX from '__Static/ColorX';
import { StyledButton } from '_Labizo/Stylizo';
import { DeleteOutlined } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from 'three';

class ItemRow extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(ItemRow.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  buyItem(mod){
    let {pid} = this.props;
    let qtyInCart = store.getQty(pid) || 0;
    store.setInCart(pid, qtyInCart + mod);
  }

  renderImage(doc){
    let images = doc.images;
    if(images.length > 0){
      return (
        <HStack width={80} height={80}>
          <img src={"Images/Products/" + doc.images[0]} alt={doc.name[store.lang]} style={{maxWidth: "95%", maxHeight: "95%", objectFit: "contain"}}/>
        </HStack>
      );
    }
  }

  renderBrand(doc){
    return (
      <HStack>
        <Typography style={{
          fontSize: 2,
          color: ColorX.GetColorCSS("greyOut")
          }}>
          {store.getBrandName(doc.brand)}
        </Typography>
        <Spacer/>
      </HStack>
    );
  }

  renderName(doc){
    return (
    <VStack width="100%" paddingTop="4%" height="fit-content">
        <HStack>
          <Typography style={{fontSize: 12, fontWeight: "bold"}}>
            {doc.name[store.lang]}
          </Typography>
          <Spacer/>
        </HStack>
        {this.renderBrand(doc)}
        <Spacer/>
      </VStack>
    );
  }

  renderInfo(doc){
    return (
      <HStack alignItems="flex-start">
        {this.renderImage(doc)}
        {this.renderName(doc)}
      </HStack>
    );
  }

  renderQty(doc){
    let qtyInCart = store.getQty(doc._id);
    return (
      <VStack height="100%">
        <Spacer/>
        <Box style={{padding: "1px 10px", height: 25}}>
          <Typography>
            {"x" + (qtyInCart || 0)}
          </Typography>
        </Box>
        <Spacer/>
      </VStack>
    );
  }

  renderTotal(doc){
    let qtyInCart = store.getQty(doc._id);
    let total = qtyInCart * doc.price;
    return(
      <VStack width="100%" height="100%">
        <Spacer/>
        <HStack>
          <Spacer/>
          <Typography>
            {"HKD$ " + total}
          </Typography>
        </HStack>
        <Spacer/>
      </VStack>
    );
  }

  renderDelete(doc){
    let del = {
      EN: "Delete",
      TC: "刪除"
    };
    return (
      <StyledButton
        onClick={() => {store.deleteInCart(doc._id)}}>
        <DeleteOutlined style={{width:20, height: 20}}/>
        <Typography style={{fontSize: 12}}>
          {del[store.lang]}
        </Typography>
      </StyledButton>
    );
  }

  render(){
    let {pid} = this.props;
    let doc = store.getProductDoc(pid);
    if(!doc){
      return (
        <Skeleton/>
      );
    }
    return (
      <HStack width="100%" paddingY={2} alignItems="flex-start" height={100}
        style={{
          borderBottom: "1px solid " + ColorX.GetColorCSS("lightGrey")
        }}>
        <HStack width="50%"
          style={{
            margin: "0 10px"
          }}>
          {this.renderInfo(doc)}
        </HStack>
        <HStack width="20%" height="100%"
          style={{
            margin: "0 10px"
          }}>
          {this.renderQty(doc)}
        </HStack>
        <HStack width="30%" height="100%"
          style={{
            margin: "0 10px"
          }}>
          {this.renderTotal(doc)}
        </HStack>
      </HStack>
    );
  }

}

export default observer(ItemRow);
