import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Accessor from '__Static/Accessor';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import _ from 'lodash';

import store from '__Static/AppStore';
import ProductCard from 'Home/Catalog/ProductCard';
import { StyledButton } from '_Labizo/Stylizo';
import Redirect from '__Static/Redirect';
import ColorX from '__Static/ColorX';
import { Typography } from '@material-ui/core';

class Favourite extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(Favourite.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  renderProducts(filtered){
    

    return _.map(filtered, (o, i) => {
      return (
        <ProductCard doc={o} key={i}/>
      );
    })
  }

  renderProductGrid(filtered){
    return (
      <HStack style={{paddingLeft: "5%"}} width="820px" flexWrap="wrap" justifyContent="flex-start">
        {this.renderProducts(filtered)}
      </HStack>
    )
  }

  render(){
    let filtered = _.filter(store.products, o => store.isFavourite(o._id));
    if(filtered.length > 0){
      return (
        <HStack>
          {this.renderProductGrid(filtered)}
        </HStack>
      );
    }else{
      let toShopping = {
        EN: "Go Shopping",
        TC: "再逛一逛~"
      };
      let noItem = {
        EN: "There is no items in your wish list.",
        TC: "你的願望清單沒有物品。"
      }
      return (
        <VStack height="100%" spacing={20}>
          <Spacer/>
          <HStack style={{width: 100, height: 100}}>
            <img src="Images/wishlist.svg" alt="wishlist" style={{width: "100%"}}/>
          </HStack>
          <Typography>
            {noItem[store.lang]}
          </Typography>
          <StyledButton onClick={() => Redirect.toHome()} 
            theme={{
              width: "100%",
              background: ColorX.GetColorCSS("elainOrange"),
              color: ColorX.GetColorCSS("pureWhite"),
              hover: {
                background: ColorX.GetColorCSS("elainOrangeDark")
              }
            }}>
            {toShopping[store.lang]}
          </StyledButton>
          <Spacer/>
        </VStack>
      );
    }
    
  }

}

export default observer(Favourite);
