import { /*Divider, */Typography } from '@material-ui/core';
//import { VStack, HStack, Spacer } from '_Labizo/Stackizo';

const schema = (lang) => [
  /*
  {
    inject: () => {
      let basic = {
        EN: "Buyer Information",
        TC: "購買人資料"
      }

      return (
        <VStack width="100%">
          <HStack>
            <Typography>{basic[lang]}</Typography>
            <Spacer/>
          </HStack>
          <Divider style={{height: 1, width: "100%"}}/>
        </VStack>
      );
    }
  },
  {
    columns: [
      {
        page: [
          {
            label: lang === "EN"? "FirstName" : "名字",
            name: "buyer.firstname",
            format: "text"
          },
          {
            label: lang === "EN"? "LastName" : "姓氏",
            name: "buyer.lastname",
            format: "text"
          },
        ]
      },
      {
        page: [
          {
            label: lang === "EN"? "Contact" : "電話",
            name: "buyer.contact",
            format: "text"
          },
          {
            label: "Email",
            name: "buyer.email",
            format: "text"
          },
        ]
      }
    ]
  },
  {
    inject: () => {
      let basic = {
        EN: "Shipping Method",
        TC: "選擇收貨方式"
      }

      return (
        <VStack width="100%" paddingTop={2}>
          <HStack>
            <Typography>{basic[lang]}</Typography>
            <Spacer/>
          </HStack>
          <Divider style={{height: 1, width: "100%"}}/>
        </VStack>
      );
    }
  },
  {
    name: "method",
    format: "select",
    selectStyle: "radio",
    selectRef: "method",
    selectCap: "label",
    selectVal: "value"
  },
  {
    inject: () => {
      let basic = {
        EN: "Shipping Address",
        TC: "送貨地址"
      }

      return (
        <VStack width="100%" paddingTop={2}>
          <HStack>
            <Typography>{basic[lang]}</Typography>
            <Spacer/>
          </HStack>
          <Divider style={{height: 1, width: "100%"}}/>
        </VStack>
      );
    }
  },
  */
  {
    label: lang === "EN"? "Address" : "地址",
    name: "address",
    format: "text"
  },
  {
    inject: 
    <Typography>
      {"預計40分鐘內到達"}
    </Typography>
  },
  /*
  {
    inject: () => {
      let basic = {
        EN: "Receiver Information",
        TC: "收件人資料"
      }

      return (
        <VStack width="100%" paddingTop={2}>
          <HStack>
            <Typography>{basic[lang]}</Typography>
            <Spacer/>
          </HStack>
          <Divider style={{height: 1, width: "100%"}}/>
        </VStack>
      );
    }
  },
  {
    label: lang === "EN"? "Same as buyer" : "與購買人一致",
    name: "sameAsBuyer",
    format: "bool"
  },
  {
    control: "sameAsBuyer",
    inverse: true,
    fold: [
      {
        columns: [
          {
            page: [
              {
                label: lang === "EN"? "FirstName" : "名字",
                name: "rec.firstname",
                format: "text"
              },
              {
                label: lang === "EN"? "LastName" : "姓氏",
                name: "rec.lastname",
                format: "text"
              },
            ]
          },
          {
            page: [
              {
                label: lang === "EN"? "Contact" : "電話",
                name: "rec.contact",
                format: "text"
              },
              {
                label: "Email",
                name: "rec.email",
                format: "text"
              },
            ]
          }
        ]
      }
    ]
  }
  */
];

export default schema;