import React, { Component } from 'react';
import Accessor from '__Static/Accessor';
import PropsType from 'prop-types';
import { StyledButton, StyledTextField } from '_Labizo/Stylizo';
import { HStack } from '_Labizo/Stackizo';
import { Typography } from '@material-ui/core';

import store from '__Static/AppStore';
import _ from 'lodash';

class InputBox extends Component {

  static propTypes = {
    onMounted: PropsType.func,
  }

  static defaultProps = {
    onMounted: null,
  }

  constructor(){
    super();
    this.state = {
      input: ""
    };
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(InputBox.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), () => {
      if(this.props.onMounted){
        this.props.onMounted({
          
        });
      }
      if(callback) callback();
    });
  }

  onKeyDown = (e) => {
    let {input} = this.state;
    if(e.keyCode === 13 && e.shiftKey === false){
      this._onSend({ text: input && input.trim() });
      e.preventDefault();
    }
    return false;
  }

  onChange = (value) => {
    this.setState({
      input: value
    });
  }

  _onSend = async () => {
    let {onSend} = this.props;
    let {input} = this.state;
    if(onSend && input && !_.isEmpty(input)){
      await onSend({ text: input && input.trim() });
      this.setState({
        input: ""
      });
    }
  }

  render(){
    let {input} = this.state;
    return (
      <HStack padding={2}>
        <StyledTextField 
          value={input} 
          onChange={(e) => this.onChange(e.target.value)}
          onKeyDown={e => this.onKeyDown(e)}/>
        <StyledButton onClick={() => {this._onSend();}}>
          <Typography>
            {store.lang === "EN"? "Send": "傳送"}
          </Typography>
        </StyledButton>
      </HStack>
    );
  }

}

export default InputBox;
