import React, { Component } from 'react';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import Accessor from '__Static/Accessor';
//import PropsType from 'prop-types';

class NoMatch extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  /* constructor(){
    super();
  } */

  componentDidMount(){
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(NoMatch.defaultProps))){
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  render(){
    return (
      <VStack>
        <Spacer/>
        <HStack width="30vw" height="30vh">
          <img src="Images/404.svg" alt="not-found"/> 
        </HStack>
        <Spacer/>
      </VStack>
    );
  }

}

export default NoMatch;
