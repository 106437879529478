import IdleDoctor from "../Images/Avatar Doctor - Idle.svg";
import OrangeBagDoctor from "../Images/Avatar Doctor - Orange Bag.svg";
import BottleDoctor from "../Images/Avatar Doctor - bottle.png";
import WindowDoctor from "../Images/Avator Doctor - window.png";
import KnifeDoctor from "../Images/Avator Doctor - Knife.png";
import SportsDoctor from "../Images/Avator Doctor - sports.png";
import FamilyDoctor from "../Images/Avator Doctor - Family.png";
import SleepDoctor from "../Images/Avator Doctor - Sleep.png";
import stabDoctor from "../Images/Avator Doctor - stab.png";
import BackDocter from "../Images/Avator Doctor - Back.png";
import PainDoctor from "../Images/Avatar Doctor - pain.png";
import WorkDoctor from "../Images/Avatar Doctor - work.png";

const ImageList = {
  0: {
    img: IdleDoctor,
    class: "Doctor0",
  },
  1: {
    img: BottleDoctor,
    class: "Doctor1",
  },
  2: {
    img: WindowDoctor,
    class: "Doctor2",
  },
  3: {
    img: OrangeBagDoctor,
    class: "Doctor3",
  },
  4: {
    img: BackDocter,
    class: "Doctor4",
  },
  5: {
    img: KnifeDoctor,
    class: "Doctor5",
  },
  6: {
    img: SportsDoctor,
    class: "Doctor6",
  },
  7: {
    img: FamilyDoctor,
    class: "Doctor7",
  },
  8: {
    img: WorkDoctor,
    class: "Doctor8",
  },
  9: {
    img: PainDoctor,
    class: "Doctor9",
  },
  10: {
    img: stabDoctor,
    class: "Doctor10",
  },
  11: {
    img: SleepDoctor,
    class: "Doctor11",
  },
};

export default ImageList;
