import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  colors,
} from "@material-ui/core";
import React, { Component } from "react";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import Accessor from "__Static/Accessor";
import MessageBox from "../MessageBox/MessageBox";
import IdleDoctor from "../Images/Avatar Doctor - Shows.png";
import Reminder from "../Images/Reminder.GIF";
import axios from "axios";
import https from "https";
import "./QR.css";
require("dotenv").config();

const url = process.env.REACT_APP_MW_PATIENT_ENDPOINT;
const email_EP = process.env.REACT_APP_EMAIL_ENDPOINT;

class QR extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.props.playAudio("/Audio/Page_QRcode.mp3");
    this.state = {
      PopUpAlert: false,
      AlertMessage: "",
      InputData: {
        session_id: "patient",
        user_id: "syva",
        input: {
          type: "text",
          method: "",
          content: {
            HKID: "",
            Name: "",
            Content: "",
          },
        },
      },
      // InputData: {
      //   session_id: "patient",
      //   user_id: "syva",
      //   input: {
      //     type: "text",
      //     method: "Check_HKID",
      //     content: {
      //       HKID: "Z683367(2)",
      //       Name: "張家俊",
      //       Content: "",
      //     },
      //   },
      // },
      logContent: "",
      deviceStatus: {},
    };
    this.hiddenInputQR = React.createRef();
    this.handleInputQR = this.handleInputQR.bind(this);
    this.websocket = null;
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
    this.hiddenInputQR.current.focus(); //focus the input box
    window.addEventListener("keydown", this.handleInputQR);
    this.connectWebSocket();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Accessor.IsIdentical(prevProps, this.props, Object.keys(QR.defaultProps))
    ) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener("keydown", this.handleInputQR);
    this.websocket.close();
  }

  connectWebSocket() {
    try {
      const host = "ws://127.0.0.1:90/echo";

      this.websocket = new WebSocket(host);

      this.websocket.onopen = () => {
        this.getDeviceStatus();
      };

      this.websocket.onmessage = (event) => {
        let retmsg = event.data;
        let jsonMsg;

        try {
          jsonMsg = JSON.parse(retmsg);
          if (jsonMsg.Type == "Reply") {
            this.processReply(jsonMsg);
          } else if (jsonMsg.Type == "Notify") {
            this.processNotify(jsonMsg);
          }
        } catch (exception) {}
      };

      this.websocket.onerror = () => {
        this.websocket = null;
        this.connectWebSocket();
      };
    } catch (e) {
      this.handleAlertOpen("掃瞄失敗，請重試");
    }
  }

  getDeviceStatus() {
    const request = {
      Type: "Request",
      Commands: [
        { Command: "Get", Operand: "OnLineStatus" },
        { Command: "Get", Operand: "DeviceName" },
        { Command: "Get", Operand: "DeviceType" },
        { Command: "Get", Operand: "DeviceSerialNo" },
        { Command: "Get", Operand: "VersionInfo" },
      ],
    };

    this.sendJson(request);
  }

  sendJson(jsonData) {
    try {
      if (this.websocket !== null) {
        this.websocket.send(JSON.stringify(jsonData));
      }
    } catch (exception) {}
  }

  processReply(msgReply) {
    if (msgReply.Command === "Get" && msgReply.Succeeded === "Y") {
      this.setState((state) => ({
        deviceStatus: {
          ...state.deviceStatus,
          [msgReply.Operand]: msgReply.Result,
        },
      }));
    }
  }

  //get HKID data(have to change)
  processNotify(msgNotify) {
    if (msgNotify.Command === "Save") {
      if (this.props.PageStatus === "Start") {
        this.props.setHKIDAlert(true);
      }
      this.props.setHKIDContent(msgNotify.Param);
      this.handleHKID();
    }
  }

  nextPage() {
    this.props.changePageStatus("Start");
  }

  IDPage() {
    this.props.changePageStatus("HKIDform");
  }

  handleInputQR(event) {
    if (event.key === "Enter") {
      let dataform = {
        session_id: "patient",
        user_id: "svya",
        input: {
          type: "text",
          method: "Check_HKID",
          content: {
            HKID: this.hiddenInputQR.current.value,
          },
        },
      };
      this.setState(
        {
          InputData: dataform,
        },
        () => {
          this.hiddenInputQR.current.value = "";
          this.confirmID();
        }
      );
    }
  }

  handleHKID() {
    try {
      let dataform = {
        session_id: "patient",
        user_id: "svya",
        input: {
          type: "text",
          method: "Check_HKID",
          content: {
            HKID: this.props.HKIDContent["ID number"],
            Name: this.props.HKIDContent["Chinese name"],
            Content: this.props.HKIDContent,
          },
        },
      };

      this.setState(
        {
          InputData: dataform,
        },
        () => {
          this.hiddenInputQR.current.value = "";
          this.confirmHKID();
        }
      );
    } catch (e) {
      this.handleAlertOpen("掃瞄失敗，請重試");
    }
  }

  confirmID() {
    let agent = new https.Agent({
      rejectUnauthorized: false,
    });

    axios
      .post(url, this.state.InputData)
      .then((response) => {
        if (response.data.Success == false) {
          this.handleAlertOpen("您的QR碼未登記，請重試或者身份證註冊。");
          this.hiddenInputQR.current.focus();
        } else if (response.data.Success == true) {
          this.props.setPatientID(this.state.InputData.input.content.HKID);
          this.nextPage();
        }
      })
      .catch((error) => {
        this.handleAlertOpen("伺服器連線異常，請聯絡職員！！");
        let subject = "HKU Server Connection Error";
        let message = "Error appears in checking ID, please check mw.";
        this.postEmail(subject, message);
        console.error(error);
      });
  }

  confirmHKID() {
    try {
      let agent = new https.Agent({
        rejectUnauthorized: false,
      });

      let HKIDCheck =
        this.state.InputData.input.content.HKID ||
        this.props.HKIDContent["ID number"];

      console.log("HKIDCheck Before", HKIDCheck);

      if (HKIDCheck != undefined) {
        HKIDCheck = HKIDCheck.replace(/[\(\)]/g, "");
        HKIDCheck = HKIDCheck.slice(0, -1) + "(" + HKIDCheck.slice(-1) + ")";
        HKIDCheck = HKIDCheck.toUpperCase();

        console.log("HKIDCheck After", HKIDCheck);
      }

      this.setState(
        (prevState) => ({
          InputData: {
            ...prevState.InputData,
            input: {
              ...prevState.InputData.input,
              content: {
                ...prevState.InputData.input.content,
                HKID: HKIDCheck,
              },
            },
          },
        }),
        () => {
          console.log("Change State", this.state.InputData.input.content.HKID);

          const hkidPattern = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;

          if (hkidPattern.test(this.state.InputData.input.content.HKID)) {
            axios
              .post(url, this.state.InputData)
              .then((response) => {
                if (
                  response.data.Success == false ||
                  response.data.payload.docs.length === 0
                ) {
                  if (response.data.message == "Invalid HKID format") {
                    this.handleAlertOpen("身份證格式錯誤，請重新掃瞄");
                  } else {
                    this.IDPage();
                  }
                } else if (response.data.Success == true) {
                  this.props.setHKID(this.state.InputData.input.content.HKID);
                  this.props.setPatientID(response.data.payload.docs[0]._id);
                  this.props.setName(
                    response.data.payload.docs[0].Patient_Name
                  );
                  this.nextPage();
                }
              })
              .catch((error) => {
                this.handleAlertOpen("伺服器連線異常，請重試！！");
                let subject = "HKU Server HKID checking Error";
                let message =
                  "There was an error appeared when checking HKID. Please check:" +
                  error;
                this.postEmail(subject, message);
                console.error(error);
              });
          } else {
            this.handleAlertOpen("身份證格式錯誤，請重新掃瞄");
          }
        }
      );
    } catch (e) {
      this.handleAlertOpen("掃瞄失敗，請重試");
    }
  }

  handleAlertOpen = (message) => {
    this.setState({
      PopUpAlert: true,
      AlertMessage: message,
    });
  };

  handleAlertClose = () => {
    this.setState({
      PopUpAlert: false,
    });
  };

  postEmail(subject, message) {
    let Input = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "report",
        content: {
          subject: subject,
          message: message,
        },
      },
    };

    axios
      .post(email_EP, Input)
      .then((response) => {
        if (response.data.Success == false) {
          let Input = {
            session_id: "patient",
            user_id: "svya",
            input: {
              type: "text",
              method: "save_log",
              content: {
                subject: subject,
                message: message,
              },
            },
          };

          axios
            .post(url, Input)
            .then((response) => {
              if (response.data.Success == false) {
                console.error("Email and server all down.");
              }
            })
            .catch((error) => {
              console.error("Email and server all down.");
            });
        }
      })
      .catch((error) => {
        let Input = {
          session_id: "patient",
          user_id: "svya",
          input: {
            type: "text",
            method: "save_log",
            content: {
              subject: subject,
              message: message,
            },
          },
        };

        axios
          .post(url, Input)
          .then((response) => {
            if (response.data.Success == false) {
              console.error("Email and server all down.");
            }
          })
          .catch((error) => {
            console.error("Email and server all down.");
          });
      });
  }

  render() {
    return (
      <div class="portal">
        <div class="HKUBackground">
          <input
            ref={this.hiddenInputQR}
            style={{ opacity: 0, position: "absolute", top: -9999 }}
            type="text"
          />
          <div class="HKUWithDoctor">
            <MessageBox
              location="top"
              text="您好！我係香港大學矯形及創傷外科嘅智能助理！麻煩您先掃瞄ＱＲ碼登記！"
              lines="1"
            ></MessageBox>
            <Box className="QRDoctor">
              <img src={IdleDoctor}></img>
              <img src={Reminder} className="Reminder"></img>
            </Box>
          </div>
        </div>
        <div class="HKUButton">
          <button
            class="buttonReset QRNextButton"
            //onClick={() => this.nextPage()}
            //onClick={() => this.confirmHKID()}
          ></button>
          <button
            class="buttonReset HKIDNextButton"
            //onClick={() => this.IDPage()}
          ></button>
        </div>
        <Dialog
          className="AlertQRpage"
          open={this.state.PopUpAlert}
          onClose={this.handleAlertClose}
          onBackdropClick={this.handleAlertClose}
        >
          <DialogContent>
            <DialogContentText>{this.state.AlertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default QR;
