import React, { Component } from "react";
import PropsType from "prop-types";
import Accessor from "__Static/Accessor";
import Avatar3D from "./Avatar3D";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import DialogBox from "./DialogBox";
import { v1 } from "uuid";
import { Chatbot, ChatDOMAIN, DOMAIN, CMSDOMAIN, SiteDOMAIN } from "__Base/config";
import axios from "axios";
import _ from "lodash";
import ErrorX from "__Static/ErrorX";
import store from "__Static/AppStore";
import InputBox from "./InputBox";
import Redirect from "__Static/Redirect";
import Bubble from "./Bubble";
import ZArray from "__Static/ZArray";
import { Button } from "@material-ui/core";

class Chat3D extends Component {
  static propTypes = {
    onMounted: PropsType.func,
    LiveChatEnabled: PropsType.bool,
    onMsgRecieved: PropsType.func,
    onMsgSelected: PropsType.func,
    viaBackend: PropsType.bool,
  };

  static defaultProps = {
    onMounted: null,
    LiveChatEnabled: true,
    onMsgRecieved: null,
    onMsgSelected: null,
    viaBackend: false,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates(() => {
      this._setChatbot(() => {
        this._regenSession();
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Chat3D.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
        sessionID: v1(),
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            Play: this._Play,
            Msg: this._Msg,
            GetActions: this._GetActions,
            Send: this._Send,
            setChatbot: this._setChatbot,
            regenSession: this._regenSession,
            restartChat: this._restartChat,
            welcomeMessage: this._welcomeMessage,
          });
        }
        setTimeout(() => {
          this._welcomeMessage();
        }, 3000);
        if (callback) callback();
      }
    );
  };

  _setChatbot = (callback) => {
    console.log("[-] START CHATBOT");
    let { viaBackend } = this.props;
    let { interval, version, channel } = Chatbot;
    let url = viaBackend ? DOMAIN + "/Middleware/Message/Send" : ChatDOMAIN + "/Talk2Elain";

    console.log(url);

    this.setState(
      {
        livechat: false,
        channel: channel,
        url: url,
        timeout: interval,
        sending: false,
        version: version,
      },
      callback
    );
  };

  _regenSession = () => {
    this.setState(
      {
        sessionID: v1(),
      },
      () => {
        this._restartChat();
      }
    );
  };

  _restartChat = () => {    
    //this._welcomeMessage();
  };

  onMountAvatar = (callbacks) => {
    this.MountAvatar = callbacks;
  };

  onMountDialogBox = (callbacks) => {
    this.MountDialogBox = callbacks;
  };

  onMountBubble = (callbacks) => {
    this.MountBubble = callbacks;
  };

  onMountInputBox = (callbacks) => {
    this.MountInputBox = callbacks;
  };

  _Play = (animation, loop = false) => {
    this.MountAvatar.Play(animation, loop);
  };

  _Msg = (msg) => {
    this.MountDialogBox.showMessage(msg);
  };

  _BMsg = (msg) => {
    this.MountBubble.showMessage(msg);
  };

  _Send = (msg) => {
    let input = {
      type: "system",
      content: msg,
    };
    this.sendToServer(input, null, true);
  };

  _GetActions = () => {
    if (this.MountAvatar) return this.MountAvatar.GetActions();
  };

  _welcomeMessage = () => {
    let input = {
      type: "system",
      content: "CMD_WELCOME",
    };
    this.sendToServer(input, null, true);
  };

  onSend = (input, id) => {
    console.log(input, id);

    let inputO = {
      type: "text",
      content: input.text,
    };
    this._BMsg(input.text);
    this.sendToServer(inputO, id, false);
  };

  sendToServer = async (input, _id, welcome = false) => {
    let { url, sessionID, channel, livechat } = this.state;
    let startTime = new Date();

    let payload = {
      session_id: SiteDOMAIN + sessionID,
      channel: channel,
      input: input,
      livechat: livechat,
      eShop: store.nav,
      timestamp: new Date(),
      lang: welcome ? store.lang : null,
    };

    console.log("[>] Data Sent: " + startTime, url, payload);

    try {
      //this.MountChat.Typing();
      let res = await axios.post(url, payload);
      //this.MountChat.Typing(false);
      if (res.data.Success === true) {
        let endTime = new Date();
        //if(_id) this.MountChat.SetStatus(_id, "received");

        let processTime = (endTime - startTime) / 1000;
        console.log("[<] Data Received: " + endTime);
        console.log("[-] Process Time: " + processTime + "s");

        /**
 * @example res.data = {
          Success: true,
          live_chat: false,
          message: [
            {
              user: { _id: 2, name: "", avatar: "https://placeimg.com/140/140/any" },
              live_chat: false,
              createdAt: "2022-01-17T02:39:58.027Z",
              msg: { text: "嗨！歡迎嚟到ELAIN 漢堡包網上特賣店！呢度有好多唔同口味嘅漢堡包㗎！", action: { name: "welcome_wave", loop: false }, quickReplies: [] },
            },
          ],
          ws: "master",
          watson_response: {
            master: {
              intents: [
                { intent: "FAQ_PAID", confidence: 0.5764955997467041 },
                { intent: "FAQ_ADDCART", confidence: 0.3823088169097901 },
                { intent: "FAQ_Farewell", confidence: 0.2934765756130219 },
                { intent: "FAQ_Greetings", confidence: 0.2751067340373993 },
                { intent: "LiveChat", confidence: 0.274016684293747 },
                { intent: "FAQ_PAYMENT", confidence: 0.27214741706848145 },
                { intent: "FAQ_PROMOTION", confidence: 0.26754520535469056 },
                { intent: "FAQ_PRODUCT", confidence: 0.2648333549499512 },
                { intent: "FAQ_DISCOUNT", confidence: 0.2636962413787842 },
                { intent: "FAQ_WANT_FISH_BURGER", confidence: 0.26327836513519287 },
              ],
              entities: [],
              input: { text: "CMD_WELCOME" },
              output: {
                generic: [],
                text: [],
                nodes_visited: ["CMD_WELCOME"],
                nodes_visited_details: [{ dialog_node: "CMD_WELCOME", title: "CMD_WELCOME", conditions: 'welcome || input.text == "CMD_WELCOME"', visit_reason: "branch_start" }],
                log_messages: [],
              },
              context: {
                init: true,
                _CFLevel: { HIGH: 0.7, MID: 0.4, CHANGE: 0.5, VAGUE: 0.005 },
                conversation_id: "c20cf6f0-773e-11ec-9bf2-df78b4d0922c",
                system: {
                  initialized: true,
                  dialog_stack: [{ dialog_node: "root" }],
                  dialog_turn_counter: 1,
                  dialog_request_counter: 1,
                  last_branch_node: "ABS_CONDITION",
                  branch_exited: true,
                  branch_exited_reason: "completed",
                },
                __ans: "WELCOME",
                metadata: { user_id: "c20cf6f0-773e-11ec-9bf2-df78b4d0922c" },
              },
              alternate_intents: true,
              user_id: "c20cf6f0-773e-11ec-9bf2-df78b4d0922c",
            },
          },
          intent: "FAQ_PAID",
          confidence: 0.5764955997467041,
          detect: { Entities: [], Semantics: [] },
          ansRes: { __ans: "WELCOME" },
          language: "TC",
          inTime: "2022-01-17T02:39:56.924Z",
          outTime: null,
          processTime: 1.7976931348623157e308,
        };
 */
        console.log("[-] MW Response: ", res.data);

        if (res.data.live_chat) this.startLiveChatPoll();
        else this.stopLiveChatPoll();

        let msg = res.data.message;
        console.log(msg);
        _.map(msg, (o, i) => {
          o.lapseTime = processTime;
        });

        //Text replace

        if (!msg || _.isEmpty(msg)) return;
        if (msg[0].msg.text) {
          let text = msg[0].msg.text;
          let LCQueue = msg[0].msg.LCQueue;
          this.setState({ LCQueue });

          if (text.startsWith("CMD_")) {
            this.SpecialReply(text);
          } else {
            let splited = text.split(" ");
            let pairs = [];
            _.map(splited, (o, i) => {
              if (o.startsWith("BRAND_")) {
                pairs.push({
                  word: o,
                  replace: store.getBrandName(o.replace("BRAND_", ""), store.lang),
                });
              }
            });
            _.map(pairs, (o, i) => {
              text = text.replace(o.word, o.replace);
            });
            this._Msg(text);
          }
        }

        //Special Handling
        if (msg[0].msg.action) {
          let { name, loop } = msg[0].msg.action;
          this.MountAvatar.Play(name, loop);
        }

        console.log(msg[0].msg.webnav);
        if (msg[0].msg.webnav) {
          try {
            let nav = JSON.parse(msg[0].msg.webnav);
            let { page, pid, cat, key, brand, price } = nav;
            setTimeout(() => {
              Redirect.toNav(page, pid, cat, key, brand, price);
            }, 1000);
          } catch (e) {
            store.Alert("Web Nav is not a JSON object", "warn");
          }
        }

        if (msg[0].msg.audio) {
          try {
            let audioloc = msg[0].msg.audio;
            let audio;
            if (audioloc.startsWith("http")) audio = new Audio(audioloc);
            else if (audioloc.startsWith("be:")) audio = new Audio(audioloc.replace("be:", CMSDOMAIN + "/tmp/audio/"));
            else if (audioloc) audio = new Audio("Audio/" + audioloc);
            console.log("playing audio", audioloc);
            if (audio) audio.play();
          } catch (e) {
            console.error(e);
          }
        }
        //quick reply
        if (msg[0].msg.quickReplies) {
          this.setState({ quickReplies: msg[0].msg.quickReplies });
        } else {
          this.setState({ quickReplies: [] });
        }
      } else {
        //store.Alert(ErrorX.Handle(res.data), "error");
      }
    } catch (e) {
      //this.MountChat.Typing(false);
      store.Alert(ErrorX.Handle(e), "error");
      //if(_id) this.MountChat.SetStatus(_id, 'pending');
    }
  };

  liveChatShoot = () => {
    let { url, sessionID, channel, count, timeout, livechat, sending, remarks } = this.state;

    if (!livechat || sending) return;
    //Send Message to MW
    let date = Math.round(new Date().getTime() / 1000);
    let payloadOut = {
      session_id: SiteDOMAIN + sessionID,
      channel: channel,
      livechat: livechat,
      input: {
        type: "system",
        content: "CMD_POLLING",
      },
      timestamp: date,
      remarks: remarks,
    };

    this.setState(
      {
        sending: true,
        count: count + 1,
      },
      async () => {
        console.log("[>] LiveChat Bullet Sent. Count:" + count);

        try {
          let res = await axios.post(url, payloadOut, { timeout: 2000 });
          console.log("[<] Bullet Received.", res.data);
          this.setState({ sending: false });

          let { Success, message, live_chat } = res.data;
          if (Success && !_.isEmpty(message)) {
            console.log("[<] Message Received.");
            this.setState({ count: 0 });

            let msg = message;
            //Text replace

            if (msg[0].msg.text) {
              let text = msg[0].msg.text;
              if (text === "CMD_ENDCHAT") {
                this.stopLiveChatPoll();
              }
              if (text.startsWith("CMD_")) {
                this.SpecialReply(text);
              } else {
                let splited = text.split(" ");
                let pairs = [];
                _.map(splited, (o, i) => {
                  if (o.startsWith("BRAND_")) {
                    pairs.push({
                      word: o,
                      replace: store.getBrandName(o.replace("BRAND_", ""), store.lang),
                    });
                  }
                });
                _.map(pairs, (o, i) => {
                  text = text.replace(o.word, o.replace);
                });
                this._Msg(text);

                let randomAction = ["yes_headNod", "yes_ok", "addShoppingCart_pullIn", "point_upperLeft", "yes_clap"];
                this._Play(ZArray.RandomOne(randomAction));
              }
            }

            if (live_chat) {
              setTimeout(() => {
                this.liveChatShoot();
              }, timeout);
            } else {
              this.stopLiveChatPoll();
            }
          } else {
            //this.stopLiveChatPoll();
          }
        } catch (e) {
          console.log(e);
          this.setState(
            {
              sending: false,
            },
            () => {
              this.liveChatShoot();
            }
          );
        }
      }
    );
  };

  startLiveChatPoll = () => {
    let { LiveChatEnabled } = this.props;
    if (!LiveChatEnabled) return;
    console.log("[-] Live Chat Start Polling");
    this.setState(
      {
        livechat: true,
      },
      () => {
        this.liveChatShoot();
      }
    );
  };

  stopLiveChatPoll = () => {
    console.log("[-] Live Chat Stop Polling");
    this.setState({
      livechat: false,
    });
  };

  SR_ACTION = (text) => {
    this._Play(text.trim());
  };

  SR_PRODUCT = (text) => {
    let filtered = store.products;
    let product = _.find(filtered, (o) => o.pid && o.pid === text.trim());
    this._Play("point_upperLeft");
    store.setNav("product", product._id);
  };

  SpecialReply = (text) => {
    let splited = text.split(" ");
    let action = splited.shift();
    let payload = splited.join(" ");
    switch (action) {
      case "CMD_PRODUCT":
        return this.SR_PRODUCT(payload);
      case "CMD_ACTION":
        return this.SR_ACTION(payload);
      default:
        return;
    }
  };

  /**
   *
   * @param {*} button
   * @returns
   */

  render() {
    let { livechat, LCQueue, quickReplies } = this.state;
    return (
      <HStack
        alignItems="flex-start"
        style={{
          width: "600px",
          height: "470px",
          position: "fixed",
          right: "50px",
          bottom: 0,
          zIndex: 50,
        }}
      >
        <Spacer />
        <HStack
          style={{
            position: "absolute",
            bottom: "450px",
            right: 20,
          }}
          justifyContent="flex-end"
        >
          <DialogBox onMounted={this.onMountDialogBox} livechat={livechat} LCQueue={LCQueue} />
        </HStack>
        <VStack
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Spacer />
          <Avatar3D onMounted={this.onMountAvatar} />
        </VStack>
        <VStack
          style={{
            position: "absolute",
            right: 180,
            zIndex: 50,
            background: "linear-gradient(0deg, rgba(256, 256, 256, 80%) 50%, transparent 50%)"
          }}
        >
          <Spacer />
          {quickReplies && quickReplies.length > 0 && <ReplyButtons quickReplies={quickReplies} onSend={this.onSend} />}
          <Bubble onMounted={this.onMountBubble} />
          <InputBox onMounted={this.onMountInputBox} onSend={this.onSend} />
        </VStack>
      </HStack>
    );
  }
}

function ReplyButtons({ quickReplies, onSend = () => {} }) {
  console.log("quickReplies", quickReplies);

  const _onClick = ({ title, payload }) => {
    if (!title || !payload) return;
    onSend({ text: payload });
  };
  return (
    <VStack height="100%" justifyContent="flex-end" spacing={2}>
      {quickReplies.map((o, i) => {
        return (
          <Button variant="contained" color={"primary"} key={i} onClick={() => _onClick(o)}>
            {o.title}
          </Button>
        );
      })}
    </VStack>
  );
}

export default Chat3D;
