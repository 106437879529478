import React, { Component } from 'react';
import Accessor from '__Static/Accessor';
import PropsType from 'prop-types';

import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MUIUtils from './_gears/MUIUtils';
 import ColorX from '__Static/ColorX';

const styles = {
  root: {
    background: MUIUtils.getMappedProps(ColorX.GetBGColorCSS, "theme.background", "theme.color"),
    borderRadius: MUIUtils.getProps('theme.borderRadius'),
    border:  MUIUtils.getProps('theme.border'),
    borderBottom: MUIUtils.getProps('theme.borderBottom'),
    borderTop: MUIUtils.getProps('theme.borderTop'),
    borderLeft: MUIUtils.getProps('theme.borderLeft'),
    borderRight: MUIUtils.getProps('theme.borderRight'),
    color: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.label", "theme.color"),
    height: MUIUtils.getProps('theme.height'),
    width: MUIUtils.getProps('theme.width'),
    padding: MUIUtils.getProps('theme.padding'),
    margin: MUIUtils.getProps('theme.margin'),
    boxShadow: MUIUtils.getProps("theme.boxShadow", "theme.color"),
    "&:hover": {
      color: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.hover.color", "theme.label", "theme.color"),
      background: MUIUtils.getMappedProps(ColorX.GetBGColorCSS, "theme.hover.background", "theme.color"),
      border: MUIUtils.getProps('theme.hover.border'),
      borderBottom: MUIUtils.getProps('theme.hover.borderBottom'),
      borderTop: MUIUtils.getProps('theme.hover.borderTop'),
      borderLeft: MUIUtils.getProps('theme.hover.borderLeft'),
      borderRight: MUIUtils.getProps('theme.hover.borderRight'),
    },
    "&:disabled": {
      color: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.disabled.color", "theme.label", "theme.color"),
      fontWeight: MUIUtils.getProps('theme.disabled.fontWeight'),
      background: MUIUtils.getMappedProps(ColorX.GetBGColorCSS, "theme.disabled.background", "theme.color"),
      border: MUIUtils.getProps('theme.disabled.border'),
      borderBottom: MUIUtils.getProps('theme.disabled.borderBottom'),
      borderTop: MUIUtils.getProps('theme.disabled.borderTop'),
      borderLeft: MUIUtils.getProps('theme.disabled.borderLeft'),
      borderRight: MUIUtils.getProps('theme.disabled.borderRight'),
    },
    display: MUIUtils.getProps('theme.display'),
    visibility: MUIUtils.getProps('theme.visibility'),
    opacity: MUIUtils.getProps('theme.opacity'),
  },
};

/**
 * @property {{
 *  color: Color,
 *  background: Color,
 *  label: Color,
 *  boxShadow: Color,
 *  hover: Color,
 * }} theme
 */
class StyledIconButton extends Component {

  static propTypes = {
    theme: PropsType.object
  }

  static defaultProps = {
    theme: {}
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props)){
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  render(){
    let props = Accessor.Exclude(this.props, Object.keys(StyledIconButton.defaultProps));
    return (
      <IconButton
        {...props}
      />
    );
  }

}

export default withStyles(styles)(StyledIconButton);
