import React, { Component } from "react";
import Accessor from "__Static/Accessor";
import ExitPopup from "./ExitPopup";
import Entrance from "./Entrance/Entrance";
import QR from "./QR/QR";
import Start from "./Start/Start";
import Question from "./Question/Question";
import Result from "./Result/Result";
import HKIDform from "./HKIDform/HKIDform";
import "./Portal.css";

let audio;

class Portal extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      HKUStatus: "QR",
      homePagePopup: false,
      questionnaire: {},
      questionStartTime: null,
      questionTimeString: {},
      currentQuestion: 1,
      ButtonDisabled: false,
      HKIDContent: {},
      PatientID: "",
      HKID: "",
      Name: "",
      HKIDAlert: false,
    };
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Accessor.IsIdentical(
        prevProps,
        this.props,
        Object.keys(Portal.defaultProps)
      )
    ) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  changePageStatus(status) {
    this.setState({
      HKUStatus: status,
    });
  }

  updateQuestionStatus(status) {
    let questionnaire = this.state.questionnaire;
    let questionTimeString = this.state.questionTimeString;
    let questionStartTime = this.state.questionStartTime;

    if (status.questionnaire) {
      for (let key in status.questionnaire) {
        questionnaire[key] = status.questionnaire[key];
      }
    }
    if (status.questionTimeString) {
      for (let key in status.questionTimeString) {
        questionTimeString[key] = status.questionTimeString[key];
      }
    }
    if (status.questionStartTime) {
      questionStartTime = status.questionStartTime;
    }
    if (status.ended) {
      this.setState({
        questionnaire: questionnaire,
        HKUStatus: "Result",
        currentQuestion: 1,
        questionStartTime: questionStartTime,
      });
    } else {
      this.setState({
        questionnaire: questionnaire,
        currentQuestion: status.currentQuestion,
      });
    }
  }

  //path2 is for scores
  playAudio(path1, path2) {
    if (!path2) {
      this.pauseAudio();
      audio = new Audio(path1);
      audio.play();
    } else {
      this.pauseAudio();
      let audio1 = new Audio(path1);
      let audio2 = new Audio(path2);

      audio1.addEventListener("ended", function () {
        audio = audio2;
        audio.play();
      });
      audio = audio1;
      audio.play();
    }
  }

  pauseAudio() {
    if (audio) {
      audio.pause();
    }
  }

  renderSelectPage() {
    switch (this.state.HKUStatus) {
      case "Entrance":
        return (
          <Entrance
            changePageStatus={this.changePageStatus.bind(this)}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
          />
        );
      case "QR":
        return (
          <QR
            HKIDAlert={this.state.HKIDAlert}
            setHKIDAlert={this.setHKIDAlert.bind(this)}
            setName={this.setName.bind(this)}
            PatientID={this.state.PatientID}
            setPatientID={this.setPatientID.bind(this)}
            HKIDContent={this.state.HKIDContent}
            setHKID={this.setHKID.bind(this)}
            changePageStatus={this.changePageStatus.bind(this)}
            PageStatus={this.state.HKUStatus}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
            setHKIDContent={this.setHKIDContent.bind(this)}
          />
        );
      case "HKIDform":
        return (
          <HKIDform
            setName={this.setName.bind(this)}
            PatientID={this.state.PatientID}
            setPatientID={this.setPatientID.bind(this)}
            HKIDContent={this.state.HKIDContent}
            setHKID={this.setHKID.bind(this)}
            changePageStatus={this.changePageStatus.bind(this)}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
          />
        );
      case "Start":
        return (
          <Start
            HKIDAlert={this.state.HKIDAlert}
            setHKIDAlert={this.setHKIDAlert.bind(this)}
            changePageStatus={this.changePageStatus.bind(this)}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
          />
        );
      case "Question":
        return (
          <Question
            changePageStatus={this.changePageStatus.bind(this)}
            updateQuestionStatus={this.updateQuestionStatus.bind(this)}
            currentQuestion={this.state.currentQuestion}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
            setButtonDisabled={this.setButtonDisabled.bind(this)}
          />
        );
      case "Result":
        return (
          <Result
            Name={this.state.Name}
            PatientID={this.state.PatientID}
            HKID={this.state.HKID}
            questionTimeString={this.state.questionTimeString}
            questionStartTime={this.state.questionStartTime}
            setPatientID={this.setPatientID.bind(this)}
            HKIDContent={this.state.HKIDContent}
            setHKID={this.setHKID.bind(this)}
            endConversation={this.endConversation.bind(this)}
            questionnaire={this.state.questionnaire}
            playAudio={this.playAudio.bind(this)}
            pauseAudio={this.pauseAudio.bind(this)}
          />
        );
      default:
        this.setState({
          HKUStatus: "Entrance",
        });
        return <Entrance />;
    }
  }

  setHomePagePopup(status) {
    this.setState({
      homePagePopup: status,
    });
  }

  setButtonDisabled(status) {
    this.setState({
      ButtonDisabled: status,
    });
  }

  setHKID(status) {
    this.setState({
      HKID: status,
    });
  }

  setPatientID(status) {
    this.setState({
      PatientID: status,
    });
  }

  setName(status) {
    this.setState({
      Name: status,
    });
  }

  setHKIDAlert(status) {
    this.setState({
      HKIDAlert: status,
    });
  }

  setHKIDContent(status) {
    if (!status) {
      console.warn("HKIDContent is not defined");
      return;
    }

    if (typeof status === "string") {
      try {
        status = JSON.parse(status);
      } catch (err) {
        console.error("Unable to parse HKIDContent:", err);
        return;
      }
    }

    this.setState({
      HKIDContent: status,
    });
  }

  renderHomePageButton() {
    switch (this.state.HKUStatus) {
      case "Entrance":
      case "QR":
      case "Result":
        return;
      default:
        return (
          <button
            class="buttonReset homePageButton"
            onClick={() => this.setHomePagePopup(true)}
            disabled={this.state.ButtonDisabled}
          ></button>
        );
    }
  }

  endConversation() {
    this.setState({
      homePagePopup: false,
      HKUStatus: "QR",
      questionnaire: {},
      currentQuestion: 1,
    });
    window.location.reload();
  }

  renderHomePagePopup() {
    if (this.state.homePagePopup) {
      return (
        <ExitPopup
          endConversation={this.endConversation.bind(this)}
          setHomePagePopup={this.setHomePagePopup.bind(this)}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderSelectPage()}
        {this.renderHomePageButton()}
        {this.renderHomePagePopup()}
      </div>
    );
  }
}

export default Portal;
