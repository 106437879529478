import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component, useContext } from "react";
import Accessor from "__Static/Accessor";
import MessageBox from "../MessageBox/MessageBox";
import QuestionList from "./QuestionList";
import ImageList from "./ImageList";
import "./Question.css";
import BTN_Arrow from "../Images/BTN_Arrow.svg";
import * as io from "socket.io-client";
import { TimerSharp } from "@material-ui/icons";
import axios from "axios";
import https from "https";
require("dotenv").config();

let intervalId;
let OfflineRetryCount = 0; //Counter of online reconnect try
const maxOfflineRetry = 20; // the max try
let audio;
let timer;
const AnsListurl = process.env.REACT_APP_MW_PATIENT_ENDPOINT;
//voice group
const endpoint = process.env.REACT_APP_MW_ENDPOINT;
const sampleRate = 16000;
const SYVAMW = process.env.REACT_APP_MW_FAQ_ENDPOINT;
let connection;
let currentRecognition;
let recognitionHistory = [];
let isRecording = false;
let processor;
let audioContext;
let audioInput;
let debug = false;

class Question extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      PopUpAlert: false,
      stopper: true,
      voiceRecord: false,
      doctor: 1,
      currentButton: null,
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      displayState: "none",
      RecognizeData: "",
      feedbackMessage: "",
      AnswerList: {},
      blockResponsesUntil: 0,
      blockVoice: false,
      StartTime: new Date(),
      disAllowAlertClose: false,
      connected: true,
    };

    this.props.playAudio(
      "/Audio/Question_" + this.props.currentQuestion + ".mp3"
    );

    this.connect();
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
    {
      this.getAnswerList();
      this.setState({
        stopper: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Accessor.IsIdentical(
        prevProps,
        this.props,
        Object.keys(Question.defaultProps)
      )
    ) {
      this.setState((state, props) => ({
        ...props,
      }));
    }
  }

  getAnswerList() {
    let agent = new https.Agent({
      rejectUnauthorized: false,
    });

    let InputData = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "getAnswerList",
        content: "",
      },
    };

    axios
      .post(AnsListurl, InputData)
      .then((response) => {
        if (!response.data.AnswerList) console.error("Get AnswerList Fail!");
        else {
          this.setState({
            AnswerList: response.data.AnswerList,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  //voice streaming part
  getMediaStream = () =>
    navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: "default",
        sampleRate: sampleRate,
        sampleSize: 16,
        channelCount: 1,
      },
      video: false,
    });

  speechRecognized = (data) => {
    if (data.isFinal) {
      currentRecognition = "...";
      recognitionHistory = [data.text, ...recognitionHistory];
    } else currentRecognition = data.text + "...";
  };

  connect = async () => {
    if (connection) {
      connection.disconnect();
    }
    connection = io.connect(endpoint, {
      path: "/voice/socket.io/",
      transports: ["websocket"],
      rejectUnauthorized: false,
    });
    connection.on("connect", () => {});
    connection.on("connect_error", (error) => {});

    connection.emit("send_message", "hello world");

    connection.emit("startGoogleCloudStream");

    connection.on("receive_message", (data) => {});

    //faq and voice answer
    connection.on("receive_audio_text", (data) => {
      this.speechRecognized(data);

      //block for 5 sec after answered
      if (
        Date.now() < this.state.blockResponsesUntil ||
        this.state.blockVoice === true
      ) {
        data = "";
      } else {
        this.VoiceAns(data);
      }
    });

    connection.on("disconnect", () => {
      console.log("disconnected", connection.id);
    });

    if (!isRecording) {
      const stream = await this.getMediaStream();

      audioContext = new window.AudioContext();

      try {
        await audioContext.audioWorklet.addModule(
          "./src/worklet/recorderWorkletProcessor.js"
        );

        audioContext.resume();

        audioInput = audioContext.createMediaStreamSource(stream);

        processor = new AudioWorkletNode(audioContext, "recorder.worklet");

        processor.connect(audioContext.destination);
        audioContext.resume();

        audioInput.connect(processor);

        processor.port.onmessage = (event) => {
          const audioData = event.data;
          connection.emit("send_audio_data", { audio: audioData });
        };
        isRecording = true;
      } catch (error) {
        console.error("Error while loading the worklet:", error);
      }
    }
  };

  disconnect = () => {
    if (!connection) return;
    connection.emit("endGoogleCloudStream");
    connection.disconnect();
    if (processor) {
      processor.disconnect();
    }
    if (audioInput) {
      audioInput.disconnect();
    }
    if (audioContext) {
      audioContext.close();
    }
    connection = undefined;
    isRecording = false;
  };

  nextPage() {
    this.props.changePageStatus("Question");
  }

  confirmFAQ(data) {
    //add a filter for the exactly same words in questionlist
    const sentences = QuestionList.map((item) => item.question);
    let foundSameWords = false;

    for (let sentence of sentences) {
      if (sentence.includes(data.text)) {
        console.log("same words in questionlist");
        foundSameWords = true;
        break;
      }
    }

    if (!foundSameWords) {
      this.setState({
        bottomMessage: data.text,
        feedbackMessage: data.text,
      });
      this.confirmVoiceMessage();
    }
  }
  confirmVoiceMessage() {
    try {
      let input = {
        session_id: "HKU",
        user_id: "syva",
        channel: "Talk2Elain",
        input: {
          type: "text",
          content: this.state.bottomMessage,
        },
        livechat: false,
        eShop: {
          page: "home",
          pid: null,
          cat: null,
          key: null,
          brand: null,
          price: null,
        },
        timestamp: "2022-09-21T04:02:18.423Z",
        lang: null,
      };

      axios
        .post(SYVAMW, input)
        .then((result) => {
          if (result.data.Success && this.state.blockVoice === false) {
            let intent =
              result.data.watson_response.master.intents[0].intent.replace(
                /FAQ_/g,
                ""
              );
            let answer = result.data.message[0].msg.text;
            if (
              result.data.ansRes.__func == "SResolve" ||
              result.data.ansRes.__ans == "NOANSWER" ||
              result.data.ansRes.__ans == "ERROR"
            ) {
              intent = "default";
              answer = "我唔明白你嘅問題，可唔可以問多次?";
            }
            this.handleSyvaOutput(intent, answer);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
    return;
  }

  handleSyvaOutput(intent, answer) {
    //block 3 sec
    this.setState({ blockResponsesUntil: Date.now() + 3000 });

    this.props.playAudio(
      window.location.origin + "/audio-clips/" + intent + ".mp3"
    );

    this.setState({
      bottomMessage: answer,
      voiceRecord: true,
      displayState: "Result",
    });
  }

  VoiceAns(data) {
    //clear timer
    if (timer) {
      clearTimeout(timer);
    }
    let foundKey = Object.keys(this.state.AnswerList).find((key) =>
      data.text.includes(key)
    );
    if (foundKey in this.state.AnswerList) {
      data.text = foundKey;
      if (data.isFinal == true) {
        if (this.state.AnswerList[data.text] == 0) {
          this.repeatVoice();
        } else if (this.state.AnswerList[data.text] == 6) {
          this.backToQuestion();
        } else {
          this.VoicePickingAns(data);
        }
      } else {
        timer = setTimeout(() => {
          if (this.state.AnswerList[data.text] == 0) {
            this.repeatVoice();
          } else if (this.state.AnswerList[data.text] == 6) {
            this.backToQuestion();
          } else this.VoicePickingAns(data);
        }, 1000);
      }
    } else {
      timer = setTimeout(() => {
        //enter faq
        this.connect();
        this.confirmFAQ(data);
      }, 1000);
    }
  }

  VoicePickingAns = (data) => {
    let num;
    if (this.state.voiceRecord == true) {
      this.backToQuestion();
    }
    if (this.props.currentQuestion <= 6) {
      num = this.state.AnswerList[data.text];
      this.AnsAudio(num, num);
    } else if (this.props.currentQuestion <= 8) {
      num = this.state.AnswerList[data.text];
      this.AnsAudio(5 + num, num);
    } else if (this.props.currentQuestion <= 10) {
      num = this.state.AnswerList[data.text];
      this.AnsAudio(10 + num, num);
    } else {
      num = this.state.AnswerList[data.text];
      this.AnsAudio(15 + num, num);
    }
  };

  AnsAudio = (ans_num, i) => {
    try {
      //block 4 sec
      this.setState({
        blockResponsesUntil: Date.now() + 4000,
        blockVoice: true,
      });

      if (!navigator.onLine) {
        throw new Error("Offline");
      }

      if (debug == false) {
        this.props.pauseAudio();
        if (audio) {
          audio.pause();
        }

        this.setState({ ButtonDisabled: true, currentButton: i });
        this.props.setButtonDisabled(true);

        let audio1 = new Audio("/Audio/ans/your_choice.mp3");
        let audio2 = new Audio("/Audio/ans/B" + ans_num + ".mp3");

        audio1.addEventListener("ended", function () {
          audio = audio2;
          audio.play();
        });
        audio2.addEventListener("ended", () => {
          this.setState({
            ButtonDisabled: false,
            currentButton: null,
            blockVoice: false,
          });
          this.props.setButtonDisabled(false);
          this.selectAnswer(i);
        });
        audio = audio1;
        audio.play();

        //try: reconnect to avoid repeating answer
        this.connect();
      } else {
        this.props.pauseAudio();
        if (audio) {
          audio.pause();
        }
        this.selectAnswer(i);
      }
    } catch (error) {
      if (error.message === "Offline") {
        //alert offline notice
        this.handleAlertOpen("網路已離線，重新連線中...", true);
        this.setState({
          connected: false,
        });

        if (intervalId) {
          clearInterval(intervalId);
        }
        // reconnect
        intervalId = setInterval(() => {
          if (navigator.onLine) {
            this.handleAlertClose(); //close alert
            clearInterval(intervalId); //stop timer
            if (OfflineRetryCount < maxOfflineRetry) {
              OfflineRetryCount = 0; // reset count
              this.setState({
                connected: true,
                displayState: "none",
              });
              this.AnsAudio(ans_num, i); //pick ans again
            } else {
              OfflineRetryCount = 0; //just reset count and don't pick ans
              this.setState({
                connected: true,
                displayState: "none",
              });
            }
          } else {
            OfflineRetryCount++;
            if (OfflineRetryCount >= maxOfflineRetry) {
              this.handleAlertOpen("網路已離線，請檢查網絡狀態", true);
            }
          }
        }, 500); // check every half second
      } else {
        //alert error type
        console.log(error);
        this.handleAlertOpen("系統異常");
      }
    }
  };

  //ans arrow for question 1
  renderAnsArrow() {
    let ArrowList = [];
    if (this.props.currentQuestion == 1) {
      for (let i = 1; i <= 5; i++) {
        ArrowList.push(<img src={BTN_Arrow} className="BTN_Arrow" />);
      }
      return <div class="AnsArrow">{ArrowList}</div>;
    }
  }

  renderBottomBar() {
    if (this.state.voiceRecord) {
      return;
    } else {
      let buttonList = [];

      if (this.props.currentQuestion <= 6) {
        for (let i = 1; i <= 5; i++) {
          buttonList.push(
            <button
              className={
                this.state.currentButton === i
                  ? "buttonReset questionValue buttonPress"
                  : "buttonReset questionValue"
              }
              id={"questionValue" + i}
              onClick={() => {
                this.AnsAudio(i, i);
              }}
              disabled={this.state.ButtonDisabled}
            ></button>
          );
        }
        return <div class="HKUButton">{buttonList}</div>;
      } else if (this.props.currentQuestion <= 8) {
        for (let i = 1; i <= 5; i++) {
          buttonList.push(
            <button
              className={
                this.state.currentButton === i
                  ? "buttonReset questionValue buttonPress"
                  : "buttonReset questionValue"
              }
              id={"questionValue" + (5 + i)}
              onClick={() => {
                this.AnsAudio(5 + i, i);
              }}
              disabled={this.state.ButtonDisabled}
            ></button>
          );
        }
        return <div class="HKUButton">{buttonList}</div>;
      } else if (this.props.currentQuestion <= 10) {
        for (let i = 1; i <= 5; i++) {
          buttonList.push(
            <button
              className={
                this.state.currentButton === i
                  ? "buttonReset questionValue buttonPress"
                  : "buttonReset questionValue"
              }
              id={"questionValue" + (10 + i)}
              onClick={() => {
                this.AnsAudio(10 + i, i);
              }}
              disabled={this.state.ButtonDisabled}
            ></button>
          );
        }
        return <div class="HKUButton">{buttonList}</div>;
      } else {
        for (let i = 1; i <= 5; i++) {
          buttonList.push(
            <button
              className={
                this.state.currentButton === i
                  ? "buttonReset questionValue buttonPress"
                  : "buttonReset questionValue"
              }
              id={"questionValue" + (15 + i)}
              onClick={() => {
                this.AnsAudio(15 + i, i);
              }}
              disabled={this.state.ButtonDisabled}
            ></button>
          );
        }
        return <div class="HKUButton">{buttonList}</div>;
      }
    }
  }

  setDoctor(number) {
    this.setState({
      doctor: this.state.doctor + number,
    });
  }

  selectAnswer(value) {
    try {
      if (!navigator.onLine) {
        throw new Error("Offline");
      }

      let output = {
        questionnaire: {},
        questionTimeString: {},
        currentQuestion: this.props.currentQuestion + 1,
        questionStartTime: this.state.StartTime.toTimeString()
          .split(" ")[0]
          .slice(0, 8),
      };
      output.questionnaire[this.props.currentQuestion] = value;
      const currentTime = new Date();
      output.questionTimeString[this.props.currentQuestion] = currentTime
        .toTimeString()
        .split(" ")[0]
        .slice(0, 8);
      output.ended = output.currentQuestion >= QuestionList.length;
      this.props.updateQuestionStatus(output);
      this.setDoctor(1);
      if (!output.ended) {
        this.props.playAudio(
          "/Audio/Question_" + this.props.currentQuestion + ".mp3"
        );
      } else {
        this.disconnect(); //stop streaming when ended
      }
    } catch (error) {
      if (error.message === "Offline") {
        //alert offline notice
        this.handleAlertOpen("網路已離線，重新連線中...", true);
        this.setState({
          connected: false,
        });
        if (intervalId) {
          clearInterval(intervalId);
        }
        // reconnect
        intervalId = setInterval(() => {
          if (navigator.onLine) {
            this.handleAlertClose(); //close alert
            clearInterval(intervalId); //stop timer
            OfflineRetryCount = 0; //just reset count and don't pick ans
          } else {
            OfflineRetryCount++;
            if (OfflineRetryCount >= maxOfflineRetry) {
              this.handleAlertOpen("網路已離線，請檢查網絡狀態", true);
            }
          }
        }, 500); // check every half second
      } else {
        //alert error type
        console.log(error);
        this.handleAlertOpen("系統異常");
      }
    }
  }

  goBack() {
    try {
      if (!navigator.onLine) {
        throw new Error("Offline");
      }

      this.props.updateQuestionStatus({
        currentQuestion: this.props.currentQuestion - 1,
      });
      this.props.playAudio(
        "/Audio/Question_" + (this.props.currentQuestion - 1) + ".mp3"
      );
      this.setDoctor(-1);
    } catch (error) {
      if (error.message === "Offline") {
        //alert offline notice
        this.handleAlertOpen("網路已離線，重新連線中...", true);
        this.setState({
          connected: false,
        });

        if (intervalId) {
          clearInterval(intervalId);
        }
        // reconnect
        intervalId = setInterval(() => {
          if (navigator.onLine) {
            this.handleAlertClose(); //close alert
            clearInterval(intervalId); //stop timer
            OfflineRetryCount = 0; //just reset count and don't pick ans
          } else {
            OfflineRetryCount++;
            if (OfflineRetryCount >= maxOfflineRetry) {
              this.handleAlertOpen("網路已離線，請檢查網絡狀態", true);
            }
          }
        }, 500); // check every half second
      } else {
        //alert error type
        console.log(error);
        this.handleAlertOpen("系統異常");
      }
    }
  }

  renderTopBar() {
    return (
      <div className="topBar">
        <span id="questionNumber">{"問題 #" + this.state.currentQuestion}</span>
        <div id="questionnaireStatusList">
          <span id="questionnaireStatus">問卷進度：</span>
          {this.renderQuestionNumbers()}
        </div>
        {this.renderGoBackButton()}
      </div>
    );
  }

  renderQuestionNumbers() {
    let statusButton = [];
    let currentQuestion = this.props.currentQuestion;

    for (let i = 1; i <= 11; i++) {
      if (i < currentQuestion) {
        statusButton.push(
          <Avatar className="questionNumberIcon questionDone">{i}</Avatar>
        );
      } else if (i == currentQuestion) {
        statusButton.push(
          <Avatar className="questionNumberIcon questionCurrent">{i}</Avatar>
        );
      } else {
        statusButton.push(
          <Avatar className="questionNumberIcon questionNew">{i}</Avatar>
        );
      }
    }

    return <div id="questionNumbersList">{statusButton}</div>;
  }

  renderGoBackButton() {
    if (this.props.currentQuestion > 1 && this.state.displayState == "none") {
      return (
        <button
          className="buttonReset"
          id="goBackToPreviousQuestion"
          onClick={() => this.goBack()}
          disabled={this.state.ButtonDisabled}
        ></button>
      );
    }
    return;
  }

  renderQuestion(questionNumber) {
    return (
      <MessageBox
        location="top"
        text={QuestionList[questionNumber].question}
        lines={QuestionList[questionNumber].lines}
      ></MessageBox>
    );
  }

  renderDoctor(doctor) {
    return (
      <img
        src={ImageList[doctor].img}
        className={ImageList[doctor].class}
      ></img>
    );
  }

  //add repeat button call repeatVoice
  renderRepeatButton() {
    return (
      <button
        className="buttonRepeat"
        id="repeatVoice"
        onClick={() => this.repeatVoice()}
        disabled={this.state.ButtonDisabled}
      ></button>
    );
  }

  repeatVoice() {
    this.props.playAudio(
      "/Audio/Question_" + this.props.currentQuestion + ".mp3"
    );
    this.connect();
  }

  backToQuestion() {
    this.setState({
      voiceRecord: false,
      blobURL: "",
      isBlocked: false,
      displayState: "none",
      bottomMessage: "",
      feedbackMessage: "",
    });
  }

  renderFAQBar() {
    switch (this.state.displayState) {
      case "Result":
        let lines = this.state.bottomMessage.length > 20 ? 2 : 1;
        let text = this.state.bottomMessage;

        if (lines === 2) {
          const middleIndex = Math.floor(text.length / 2);
          text = text.slice(0, middleIndex) + "<br/>" + text.slice(middleIndex);
        }

        return (
          <div className="voiceBottomBar">
            <div className="feedbackBox">
              <span>{this.state.feedbackMessage}</span>
            </div>
            <MessageBox
              location="bottom"
              text={text}
              lines={lines}
            ></MessageBox>
            <button
              id="backToQuestionBottom"
              className="buttonReset"
              onClick={() => this.backToQuestion()}
            ></button>
          </div>
        );
      default:
        return;
    }
  }

  handleAlertOpen = (message, disableClose) => {
    this.props.setButtonDisabled(true);
    this.setState({
      PopUpAlert: true,
      AlertMessage: message,
      disAllowAlertClose: disableClose,
      ButtonDisabled: true,
    });
  };

  handleAlertClose = () => {
    this.props.setButtonDisabled(false);
    this.setState({
      PopUpAlert: false,
      ButtonDisabled: false,
    });
  };

  render() {
    return (
      <div class="portal">
        <div class="HKUBackground">
          {this.renderTopBar()}
          <div class="HKUWithDoctor">
            {this.renderQuestion(this.props.currentQuestion)}
            <Box className="QRDoctor">
              {this.renderDoctor(this.state.doctor)}
            </Box>
          </div>
          <div class="voiceReatButton">{this.renderRepeatButton()}</div>
          {this.renderAnsArrow()}
        </div>

        {this.renderBottomBar()}
        {this.renderFAQBar()}
        <Dialog
          className="AlertQRpage"
          open={this.state.PopUpAlert}
          onClose={this.handleAlertClose}
          disableBackdropClick={this.state.disAllowAlertClose}
        >
          <DialogContent>
            <DialogContentText>{this.state.AlertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Question;
