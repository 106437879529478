import _ from 'lodash';
import {makeAutoObservable, runInAction} from 'mobx';
import { toJS, autorun, set } from 'mobx';
import Accessor from './Accessor';
import xBrowser from './xBrowser';

class AppStore {

  browser = "";
  user = {};
  server = {};
  alert = {};
  loading = false;
  initialized = false;
  ask = {};

  lang = "TC";
  categories = [];
  products = [];
  brands = [];
  favourite = [];
  history = [];
  cart = {};
  nav = {
    page: 'home',
    pid: null,
    cat: null,
    key: null,
    brand: null,
    price: null
  };
  navLoading = false;
  chat3D = null;

  constructor(){
    makeAutoObservable(this);
    
    let firstRun = true;

    autorun(() => {
      // on load check if there's an existing store on 
      // localStorage and extend the store
      if (firstRun) {
        let existingStore = window.localStorage.getItem('store');
  
        if (existingStore) {
          set(this, JSON.parse(existingStore));
        }
      }
  
      // from then on serialize and save to localStorage
      let serializedThis = toJS(this);
      window.localStorage.setItem(
         'store', 
         JSON.stringify(serializedThis)
      );
    });
  
    firstRun = false;

  }

  setBrowser(){
    runInAction(() => {
      let browser = xBrowser.getBrowser();
      this.browser = browser;
    });
  }

  setUser(user){
    runInAction(() => {
      this.user = user;
    });
  }

  clearUser(){
    runInAction(() => {
      this.user = {};
    });
  }

  setServer(server){
    runInAction(() => {
      this.server = server;
    });
  }

  Alert(message, severity = "info"){
    runInAction(() => {
      this.alert = {
        message,
        severity
      };
    });
  }

  Ask(title, message = "", onConfirm = null, onCancel = null, autoClose = true){
    runInAction(() => {
      this.ask = {
        title, 
        message, 
        onConfirm,
        onCancel,
        buttons: ["OK", "Cancel"],
        showCloseIcon: false,
        loading: false,
        autoClose: autoClose
      };
    });
  }

  SetAskLoading(f){
    runInAction(() => {
      this.ask.loading = f;
    });
  }

  Form(title, message = "", inner = null, onConfirm = null, onCancel = null){
    runInAction(() => {
      this.ask = {
        title, 
        message, 
        onConfirm,
        onCancel,
        inner,
        buttons: [],
        showCloseIcon: true
      };
    });
  }

  Pop(title, message = "", onConfirm = null){
    runInAction(() => {
      this.ask = {
        title, 
        message, 
        onConfirm,
        buttons: ["OK"]
      };
    });
  }

  clearAlert(){
    runInAction(() => {
      this.alert = {};
    });
  }

  clearAsk(){
    runInAction(() => {
      this.ask = {};
    });
  }

  isLoggedIn(){
    return !_.isEmpty(this.user);
  }

  isLoading(f){
    runInAction(() => {
      this.loading = f;
    });
  }

  setInitialized(f){
    runInAction(() => {
      this.initialized = f;
    });
  }

  isInitialized(){
    return this.initialized;
  }

  setCategories(cats){
    runInAction(() => {
      this.categories = cats;
    });
  }

  setProducts(prds){
    runInAction(() => {
      this.products = prds;
    });
  }

  setBrands(brands){
    runInAction(() => {
      this.brands = brands;
    });
  }

  setFavourite(id){
    runInAction(() => {
      if(this.favourite.includes(id)){
        this.favourite = this.favourite.filter(o => o !== id);
      }else{
        this.favourite.push(id);
      }
    });
  }

  clearFavourite(){
    runInAction(() => {
      this.favourite = [];
    });
  }

  countFavourite(){
    return this.favourite.length;
  }

  setInCart(id, qty){
    runInAction(() => {
      if(qty <= 0){
        this.deleteInCart(id);
      }else{
        Accessor.Set(this.cart, id.toString() + ".qty", qty);
      }
      
    });
  }

  deleteInCart(id){
    runInAction(() => {
      Accessor.Delete(this.cart, id.toString());
    });
  }

  clearProducts(){
    runInAction(() => {
      this.products = [];
    });
  }

  clearCart(){
    runInAction(() => {
      this.cart = {};
    });
  }

  countCart(){
    return Object.keys(this.cart).length;
  }

  setLang(lang){
    runInAction(() => {
      this.lang = lang;
    });
  }

  getCategoryName(code){
    let filtered = this.categories.filter(o => o.code === code);
    if(filtered.length === 1) return filtered[0].name[this.lang];
    return "";
  }

  getBrandName(code){
    let filtered = this.brands.filter(o => o.code === code);
    if(filtered.length === 1) return filtered[0].name[this.lang];
    return "";
  }

  getProductDoc(id){
    let filtered = this.products.filter(o => o._id === id);
    if(filtered.length === 1) return filtered[0];
    return {};
  }

  getSelectedProductDoc(){
    if(!this.nav.pid) return {};
    let filtered = this.products.filter(o => o._id === this.nav.pid);
    if(filtered.length === 1) return filtered[0];
    return {};
  }

  isFavourite(id){
    return this.favourite.includes(id);
  }

  isInCart(id){
    return !_.isEmpty(Accessor.Get(this.cart, id));
  }
  
  getQty(id){
    return Accessor.Get(this.cart, id + ".qty");
  }

  setNavLoading(f){
    runInAction(() => {
      this.navLoading = f;
    });
  }

  setNav(page, pid = null, cat = null, key = null, brand = null, price = null){
    if(brand && brand.startsWith("BRAND_")){
      brand = brand.replace("BRAND_", "");
    }
    this.setNavLoading(true);
    runInAction(() => {
      this.nav = {
        page: page,
        pid: pid,
        cat: cat,
        key: key,
        brand: brand,
        price: price
      };
    });
    setTimeout(() => {
      this.setNavLoading(false);
    }, 500);
  }

  clearNav(){
    runInAction(() => {
      this.nav = {
        page: 'home',
        pid: null,
        cat: null,
        key: null,
        brand: null,
        price: null
      };
    });
  }

  getPage(){
    return this.nav.page;
  }

  getSelectedPID(){
    return this.nav.pid;
  }

  getSelectedCategory(){
    return this.nav.cat;
  }

  getSelectedKey(){
    return this.nav.key;
  }

  getSelectedBrand(){
    return this.nav.brand;
  }

  getSelectedPriceRange(){
    return this.nav.price;
  }

  pushHistory(nav){
    this.history.push(nav);
  }

  backOnce(){
    return this.history.pop();
  }

  setChat3D(mount){
    runInAction(() => {
      this.chat3D = mount;
    });
  }

}

const store = new AppStore();
export default store;