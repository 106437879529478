import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Accessor from '__Static/Accessor';
import {VStack} from '_Labizo/Stackizo';
import store from '__Static/AppStore';

import { withRouter } from 'react-router';
import QueryString from '__Static/QueryString';
import Catalog from './Catalog/Catalog';
import Product from './Product/Product';
import Cart from './Cart/Cart';
import Favourite from './Favourite/Favourite';
import Payment from './Payment/Payment';

class Home extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {
      
    };
    
  }

  componentDidMount(){
    console.log(this.props.location);
    console.log(QueryString.Parse(this.props.location.search));
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(Home.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  renderPage(){
    switch(store.getPage()){
      default: case 'home': return <Catalog/>
      case 'product': return <Product/>;
      case 'cart': return <Cart/>;
      case 'favourite': return <Favourite/>;
      case 'payment': return <Payment/>;
    }
  }

  render(){
    return (
      <VStack style={{width: "100%"}} spacing={10}>
        {this.renderPage()}
      </VStack>
    );
  }

}

export default withRouter(observer(Home));
