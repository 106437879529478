import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

//react
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//page
import Container from "./Container/Container";
import Home from "./Home/Home";
import NoMatch from "./NoMatch/NoMatch";
import Denied from "./Denied/Denied";
import Portal from "./Portal/Portal";

//css
import "./index.css";
import "./preset.css";

//others
import * as serviceWorker from "./serviceWorker";

//store
import store from '__Static/AppStore';

//mute the console on production launch
// if (process.env.NODE_ENV === "production") {
//   window.console.log = () => { };
// }

store.isLoading(false);
store.clearAsk();
store.clearFavourite();
store.clearCart();
store.clearProducts();
store.clearNav();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/' exact component={Portal} />
      <Route component={NoMatch} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
