import React, { Component } from "react";
import { observer } from "mobx-react";
import Accessor from "__Static/Accessor";

import { VStack, HStack, Spacer } from "_Labizo/Stackizo";
import store from "__Static/AppStore";

import ColorX from "__Static/ColorX";
import { StyledButton } from "_Labizo/Stylizo";
import _ from "lodash";
import Redirect from "__Static/Redirect";
import ProductCard from "./ProductCard";
import { Skeleton } from "antd";
import "./Catalog.scss";

class Catalog extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Catalog.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  renderButton() {
    return _.map(store.categories, (o, i) => {
      return (
        <StyledButton onClick={() => Redirect.toCategory(o.code)} key={i} className={"left-menu-btn"}>
          {o.name[store.lang]}
        </StyledButton>
      );
    });
  }

  renderMenu(width = "fit-content") {
    return (
      <VStack width={width} style={{ position: "sticky", top: 50, height: "fit-content" }} alignItems="flex-start">
        {this.renderHome()}
        {this.renderButton()}
      </VStack>
    );
  }

  renderProducts() {
    let filtered = store.products;
    if (store.getSelectedCategory()) {
      filtered = filtered.filter((o) => o.categories.includes(store.getSelectedCategory()));
    }

    if (store.getSelectedBrand()) {
      filtered = filtered.filter((o) => o.brand === store.getSelectedBrand());
    }

    if (store.getSelectedKey()) {
      filtered = filtered.filter((o) => o.keywords.includes(store.getSelectedKey()));
    }

    if (store.getSelectedPriceRange()) {
      let { min, max, discount } = store.getSelectedPriceRange();
      if (min) {
        filtered = filtered.filter((o) => o.price >= min);
      }
      if (max) {
        filtered = filtered.filter((o) => o.price <= max);
      }
      if (discount) {
        filtered = filtered.filter((o) => o.prev_price && o.prev_price > o.price);
      }
    }

    return _.map(filtered, (o, i) => {
      if (store.navLoading) {
        return <Skeleton key={i} active />;
      }
      return <ProductCard doc={o} key={i} />;
    });
  }

  renderProductGrid() {
    return (
      <HStack style={{ paddingLeft: "20px" }} width="90%" flexWrap="wrap" justifyContent="space-between">
        {this.renderProducts()}
      </HStack>
    );
  }

  renderHome(width = "fit-content") {
    let name = {
      EN: "Home",
      TC: "首頁",
    };
    return (
      <StyledButton onClick={() => Redirect.toHome()} className="left-menu-btn">
        {name[store.lang]}
      </StyledButton>
    );
  }

  render() {
    return (
      <VStack style={{ width: "100%" }} spacing={10}>
        <HStack alignItems="flex-start">
          {this.renderMenu("10%")}
          {this.renderProductGrid()}
        </HStack>
      </VStack>
    );
  }
}

export default observer(Catalog);
