import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Accessor from '__Static/Accessor';

import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import { StyledButton } from '_Labizo/Stylizo';
import { ArrowBackIos } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Redirect from '__Static/Redirect';
import ColorX from '__Static/ColorX';

import store from '__Static/AppStore';
import _ from 'lodash';
import Formizo from '_Labizo/Formizo';

import ItemRow from './ItemRow';
import schema from './schema';

class Payment extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(Payment.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  renderRows(){
    return _.map(store.cart, (o, i) => {
      return <ItemRow pid={i} key={i}/>;
    });
  }

  renderSummary(){
    let summary = {
      EN: "Summary",
      TC: "結算"
    };
    let totalLabel = {
      EN: "Total",
      TC: "總額"
    };
    let qtyLabel = {
      EN: "Item Count",
      TC: "貨品件數"
    };
    let sum = 0;
    let tqty = 0;
    _.map(store.cart, (o, i) => {
      let doc = store.getProductDoc(i);
      sum += o.qty * doc.price;
      tqty += o.qty;
    });

    let itemStyle = {
      padding: "5px 10px"
    };

    return(
      <VStack width="40%" 
        height="fit-content"
        style={{
          background: ColorX.GetColorCSS("lightGrey", 0.5),
          margin: "0 50px"
          }}>
        <HStack paddingY={1}
          style={{
            borderBottom: "1px solid " + ColorX.GetColorCSS("grey")
            }}>
          <Typography>
            {summary[store.lang]}
          </Typography>
        </HStack>
        <VStack width="100%">
          <HStack>
            <Typography style={itemStyle}>
              {qtyLabel[store.lang]}
            </Typography>
            <Spacer/>
            <Typography style={itemStyle}>
              {tqty}
            </Typography>
          </HStack>
          <VStack 
            style={{
              overflowY: "auto", 
              background: ColorX.GetColorCSS("pureWhite"),
              margin: "0 2px",
              maxHeight: 450
            }}>
            {this.renderRows()}
          </VStack>
          <HStack>
            <Typography style={itemStyle}>
              {totalLabel[store.lang]}
            </Typography>
            <Spacer/>
            <Typography style={{
              ...itemStyle,
              color: ColorX.GetColorCSS("red"),
              fontWeight: "bold",
              fontSize: 18
            }}>
              {"HKD$ " + sum}
            </Typography>
          </HStack>
          {this.renderToPayment()}
        </VStack>
      </VStack>
    );
  }

  onMountFormizo = (callbacks) => {
    this.MountFormizo = callbacks;
  }

  renderForm(){
    return (
      <VStack width="60%">
        <Formizo
          width="100%"
          height="100%"
          form="login"
          schema={schema(store.lang)}
          buttons={[]}
          buttonsAlign={"center"}
          enableInlineSubmit={false}
          fieldStyle={"outlined"}
          fieldSize="small"
          addOns={{
            method: [
              {
                label: store.lang === "EN" ?
                  "S.F. Express (3-5 working days, only HK and Macau)":
                  "順豐快遞 (3-5個工作天送達，只支持香港及澳門地區)",
                value: "SF"
              },
              {
                label: store.lang === "EN" ?
                  "EF Locker (3-5 working days, only HK and Macau)":
                  "順便智能櫃 (3-5個工作天送達，只支持香港及澳門地區)",
                value: "EF"
              }
            ]
          }}
          />
      </VStack>
    );
  }

  renderToPayment(){
    let bill = {
      EN: "Proceed to Payment",
      TC: "付款"
    };
    return (
      <HStack>
        <StyledButton
          onClick={() => { 
            store.chat3D.Send("CMD_PAID"); 
            setTimeout(() => {
              store.clearCart(); 
            }, 2000);
          }}
          theme={{
            width: "100%",
            borderRadius: "0px",
            background: ColorX.GetColorCSS("elainOrange"),
            color: ColorX.GetColorCSS("pureWhite"),
            hover: {
              background: ColorX.GetColorCSS("elainOrangeDark")
            }
          }}>
          {bill[store.lang]}
        </StyledButton>
      </HStack>
    );
  }

  renderContinue(){
    let cont = {
      EN: "Back to Cart",
      TC: "返回購物車"
    };
    return (
      <HStack>
        <StyledButton 
          onClick={() => { Redirect.toCart(); }}
          theme={{
            background: ColorX.GetColorCSS("transparent"),
            color: ColorX.GetColorCSS("greyOut"),
            hover: {
              background: ColorX.GetColorCSS("transparent"),
              color: ColorX.GetColorCSS("grey"),
            }
          }}>
          <ArrowBackIos/>
          <Typography>
            {cont[store.lang]}
          </Typography>
        </StyledButton>
        <Spacer/>
      </HStack>
    );
  }

  renderTitle(){
    let cart = {
      EN: "Checkout",
      TC: "結算"
    };
    return (
      <HStack width="98%" paddingTop={1}>
        <Typography style={{fontSize: 24}}>
          {cart[store.lang]}
        </Typography>
        <Spacer/>
      </HStack>
    );
  }

  render(){
    return (
      <VStack width="80%" spacing={10}>
        {this.renderContinue()}
        {this.renderTitle()}
        <HStack alignItems="flex-start">
          {this.renderForm()}
          {this.renderSummary()}
        </HStack>
      </VStack>  
    );
  }

}

export default observer(Payment);
