import store from './AppStore';

class Redirect {

  static toNav = (page, pid = null, cat = null, key = null, brand = null, price = null) => {
    store.setNav(page, pid, cat, key, brand, price);
  }

  static toHome = () => {
    store.setNav('home');
  }

  static toCart = () => {
    store.setNav('cart');
  }

  static toFavourite = () => {
    store.setNav('favourite');
  }

  static toProduct = (id) => {
    store.setNav('product', id);
  }

  static toCategory = (cat) => {
    store.setNav('home', null, cat);
  }

  static toKey = (key) => {
    store.setNav('home', null, null, key);
  }

  static toPayment = () => {
    store.setNav('payment');
  }

}

export default Redirect;