import React, { Component } from "react";
import Accessor from "__Static/Accessor";

import store from "__Static/AppStore";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import { Box, Paper, Typography } from "@material-ui/core";
import { StyledButton } from "_Labizo/Stylizo";
import Redirect from "__Static/Redirect";
import { Add, Favorite, FavoriteBorderOutlined, Remove } from "@material-ui/icons";
import { observer } from "mobx-react";
import ColorX from "__Static/ColorX";
import { Skeleton } from "three";

class Product extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Product.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  buyItem(mod) {
    let pid = store.getSelectedPID();
    let qtyInCart = store.getQty(pid) || 0;
    store.chat3D.Send("CMD_ADDCART");
    store.setInCart(pid, qtyInCart + mod);
  }

  renderStock(doc) {
    let stock = {
      EN: "@stock remain in stock.",
      TC: "庫存剩餘 @stock 件",
    };
    let str = stock[store.lang].replace("@stock", doc.stock);
    return (
      <HStack width={200}>
        <Typography>{str}</Typography>
      </HStack>
    );
  }

  renderQty(doc) {
    let qtyInCart = store.getQty(doc._id);
    let theme = {
      width: 40,
      minWidth: 40,
      background: ColorX.GetColorCSS("elainOrange"),
      borderRadius: "0px",
      color: ColorX.GetColorCSS("pureWhite"),
      hover: {
        background: ColorX.GetColorCSS("elainOrangeDark"),
      },
    };
    return (
      <HStack>
        <StyledButton
          onClick={() => {
            this.buyItem(-1);
          }}
          theme={theme}
        >
          <Remove />
        </StyledButton>
        <Box style={{ border: "1px solid", padding: "5px 30px" }}>
          <Typography>{qtyInCart || 0}</Typography>
        </Box>
        <StyledButton
          onClick={() => {
            this.buyItem(1);
          }}
          theme={theme}
        >
          <Add />
        </StyledButton>
        {this.renderStock(doc)}
        <Spacer />
      </HStack>
    );
  }

  renderButton(doc) {
    let buy = {
      EN: "Buy Now",
      TC: "立即購買",
    };
    let addCart = {
      EN: "Add to Cart",
      TC: "加入購物車",
    };

    return (
      <HStack paddingBottom="4%" spacing={20}>
        <StyledButton
          onClick={() => {
            this.buyItem(1);
            Redirect.toCart();
          }}
          theme={{
            width: 140,
            background: ColorX.GetColorCSS("elainOrange"),
            borderRadius: "0px",
            color: ColorX.GetColorCSS("pureWhite"),
            hover: {
              background: ColorX.GetColorCSS("elainOrangeDark"),
            },
          }}
        >
          {buy[store.lang]}
        </StyledButton>
        <StyledButton
          onClick={() => {
            this.buyItem(1);
          }}
          theme={{
            width: 140,
            background: ColorX.GetColorCSS("greyOut"),
            borderRadius: "0px",
            color: ColorX.GetColorCSS("pureWhite"),
            hover: {
              background: ColorX.GetColorCSS("grey"),
            },
          }}
        >
          {addCart[store.lang]}
        </StyledButton>
        <Spacer />
      </HStack>
    );
  }

  renderDescription(doc) {
    return (
      <HStack width="100%">
        <Typography>{doc.description[store.lang]}</Typography>
        <Spacer />
      </HStack>
    );
  }

  renderBrand(doc) {
    return (
      <HStack>
        <Typography
          style={{
            fontSize: 16,
            color: ColorX.GetColorCSS("greyOut"),
          }}
        >
          {store.getBrandName(doc.brand)}
        </Typography>
        <Spacer />
      </HStack>
    );
  }

  renderOldPrice(doc) {
    if (doc.prev_price && doc.prev_price !== doc.price) {
      return (
        <HStack>
          <Typography
            style={{
              color: ColorX.GetColorCSS("greyOut"),
              fontSize: 16,
              textDecoration: "line-through",
            }}
          >
            {"HKD$ " + doc.prev_price}
          </Typography>
          <Spacer />
        </HStack>
      );
    }
  }

  renderPrice(doc) {
    return (
      <VStack width="100%" height="fit-content">
        {this.renderOldPrice(doc)}
        <HStack>
          <Typography style={{ color: ColorX.GetColorCSS("red"), fontSize: 20, fontWeight: "bold" }}>{"HKD$ " + doc.price}</Typography>
          <Spacer />
        </HStack>
      </VStack>
    );
  }

  renderFavourite(doc) {
    let addFav = {
      EN: "Add to My Favourite",
      TC: "加入到我的最愛",
    };
    let revFav = {
      EN: "Remove from My Favourite",
      TC: "從我的最愛移除",
    };
    if (store.isFavourite(doc._id)) {
      return (
        <StyledButton
          style={{ color: "red" }}
          size="small"
          onClick={() => {
            store.setFavourite(doc._id);
          }}
        >
          <Favorite />
          <Typography style={{ paddingLeft: 5, color: ColorX.GetColorCSS("grey") }}>{revFav[store.lang]}</Typography>
        </StyledButton>
      );
    } else {
      return (
        <StyledButton
          size="small"
          onClick={() => {
            store.setFavourite(doc._id);
          }}
        >
          <FavoriteBorderOutlined />
          <Typography style={{ paddingLeft: 5, color: ColorX.GetColorCSS("grey") }}>{addFav[store.lang]}</Typography>
        </StyledButton>
      );
    }
  }

  renderName(doc) {
    return (
      <VStack width="100%" paddingTop="4%" height="fit-content">
        <HStack>
          <Typography style={{ fontSize: 24, fontWeight: "bold" }}>{doc.name[store.lang]}</Typography>
          <Spacer />
        </HStack>
        {this.renderBrand(doc)}
      </VStack>
    );
  }

  renderDetail(doc) {
    return (
      <VStack width="50%" spacing={20} height={"100%"}>
        {this.renderName(doc)}
        {this.renderDescription(doc)}
        {this.renderPrice(doc)}
        <Spacer />
        <HStack>
          {this.renderFavourite(doc)}
          <Spacer />
        </HStack>
        {this.renderQty(doc)}
        {this.renderButton(doc)}
      </VStack>
    );
  }

  renderImage(doc) {
    let images = doc.images;
    if (images.length > 0) {
      return (
        <HStack width="50%" height={550}>
          <Paper style={{ width: 500, height: 500, margin: "4%" }}>
            <HStack style={{ width: 400, height: "100%" }} alignItems="center">
              <img src={"Images/Products/" + doc.images[0]} alt={doc.name[store.lang]} style={{ maxWidth: "95%", maxHeight: "95%", objectFit: "contain" }} />
            </HStack>
          </Paper>
        </HStack>
      );
    }
  }

  renderNav(doc) {
    let home = {
      EN: "Home",
      TC: "首頁",
    };

    return (
      <HStack paddingLeft="1%">
        <StyledButton
          onClick={() => Redirect.toHome()}
          theme={{
            hover: {
              background: "transparent",
              fontWeight: "bold",
            },
          }}
        >
          {home[store.lang]}
        </StyledButton>
        <Typography>/</Typography>
        <StyledButton
          onClick={() => Redirect.toCategory(doc.categories[0])}
          theme={{
            hover: {
              background: "transparent",
              fontWeight: "bold",
            },
          }}
        >
          {store.getCategoryName(doc.categories[0])}
        </StyledButton>
        <Typography>/</Typography>
        <Typography style={{ paddingLeft: 10 }}>{store.getBrandName(doc.brand) + " " + doc.name[store.lang]}</Typography>
        <Spacer />
      </HStack>
    );
  }

  render() {
    let doc = store.getSelectedProductDoc();
    if (!doc) {
      return (
        <VStack>
          <Skeleton />
        </VStack>
      );
    }
    return (
      <VStack>
        {this.renderNav(doc)}
        <HStack alignItems="flex-start" height={500}>
          {this.renderImage(doc)}
          {this.renderDetail(doc)}
        </HStack>
      </VStack>
    );
  }
}

export default observer(Product);
