import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import React, { Component } from 'react';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import Accessor from '__Static/Accessor';

class ExitPopup extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div>
        <Dialog open={true} id="endConversationPopup">
          <DialogTitle id="endConversationTitle">{"確定要結束問卷嗎？"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="endConversationContent">
              所有已經作答的問題將不會作記錄
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <HStack>
              <button class="buttonReset" id="endConversationCancel" onClick={() => this.props.setHomePagePopup(false)}></button>
              <button class="buttonReset" id="endConversationConfirm" onClick={() => this.props.endConversation()}></button>
            </HStack>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

}

export default ExitPopup;
