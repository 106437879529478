class ZArray {

  /**
   * Random Array Object
   * @param {[]} array 
   */
  static RandomOne(array){
    let len = array.length;
    let idx = Math.floor(Math.random() * Math.floor(len));
    return array[idx];
  }
}

export default ZArray;