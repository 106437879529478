import React, { Component } from "react";
import Accessor from "__Static/Accessor";
import PropsType from "prop-types";
import { Typography } from "@material-ui/core";
import htmlParser from "html-react-parser";

import "./DialogBox.scss";
import ZTagParser from "_Utilities/ZChat/ZCMsg/ZCMHTMLTags/ZTagParser";

class Bubble extends Component {
  static propTypes = {
    onMounted: PropsType.func,
  };

  static defaultProps = {
    onMounted: null,
  };

  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(Bubble.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      () => {
        if (this.props.onMounted) {
          this.props.onMounted({
            showMessage: this._showMessage,
          });
        }
        if (callback) callback();
      }
    );
  };

  ReplaceSpecialTags = (text) => {
    let parsed = htmlParser(text);
    return ZTagParser.Parse("", parsed, {});
  };

  _showMessage = (msg) => {
    let blocks = this.ReplaceSpecialTags(msg);
    this.setState(
      {
        msg: blocks,
        show: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            show: true,
          });
        }, 500);
      }
    );
  };

  render() {
    let { msg, show } = this.state;
    return (
      <div className={"rev speech-container " + (show ? "show" : "hide")}>
        <div className={"question"}>
          <Typography>{msg}</Typography>
        </div>
      </div>
    );
  }
}

export default Bubble;
