import React, { Component } from "react";
import store from "../__Static/AppStore";
import { observer } from "mobx-react";

import { Backdrop, Box, CircularProgress, Snackbar } from "@material-ui/core";
import { SnackAlert, StyledButton, StyledLinearProgress } from "_Labizo/Stylizo";
import _ from "lodash";
import { withRouter } from "react-router";

import "./Container.css";
import { when } from "mobx";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import StyledIconButton from "_Labizo/Stylizo/StyledIconButton";
import { Close } from "@material-ui/icons";
import ColorX from "__Static/ColorX";
import Chat3D from "_Utilities/ZAvatar/Chat3D";
import NavBar from "./NavBar";

class Container extends Component {
  constructor() {
    super();
    this.state = {
      snackOpen: false,
      loadingOpen: false,
      dialogOpen: false,
      buttonWidth: "100px",
      cartLength: 0,
    };
  }

  componentDidMount() {
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState) {
    when(
      () => this.state.snackOpen !== !_.isEmpty(store.alert),
      () => {
        this.setState({
          snackOpen: !_.isEmpty(store.alert),
        });
      }
    );

    when(
      () => this.state.loadingOpen !== store.loading,
      () => {
        this.setState({
          loadingOpen: store.loading,
        });
      }
    );

    when(
      () => this.state.dialogOpen !== !_.isEmpty(store.ask),
      () => {
        this.setState({
          dialogOpen: !_.isEmpty(store.ask),
        });
      }
    );
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  closeSnack = () => {
    this.setState(
      {
        snackOpen: false,
      },
      () => {
        setTimeout(() => {
          store.clearAlert();
        }, 1000);
      }
    );
  };

  _onOK = async () => {
    if (store.ask.onConfirm && typeof store.ask.onConfirm === "function") {
      store.SetAskLoading(true);
      let res = await store.ask.onConfirm();
      if (store.ask.autoClose) {
        store.SetAskLoading(false);
        if (!res || res.Success === undefined || (res && res.Success)) {
          store.clearAsk();
        }
      }
    } else {
      store.clearAsk();
    }
  };

  _onCancel = async () => {
    if (store.ask.onCancel && typeof store.ask.onCancel === "function") {
      store.SetAskLoading(true);
      await store.ask.onCancel();
      store.SetAskLoading(false);
    }
    store.clearAsk();
  };

  renderButtons() {
    let { buttonWidth } = this.state;
    let buttonsJSX = {
      OK: (
        <StyledButton className={"formizo-h-m"} key={0} width={buttonWidth} theme={{ color: "green", background: "white" }} onClick={this._onOK} disabled={store.ask.loading}>
          <i className="fas fa-check" />
          <div className="formizo-h-m">OK</div>
        </StyledButton>
      ),
      Cancel: (
        <StyledButton className={"formizo-h-m"} key={2} width={buttonWidth} theme={{ color: "red", background: "white" }} onClick={this._onCancel} disabled={store.ask.loading}>
          <i className="fas fa-ban" />
          <div className="formizo-h-m">Cancel</div>
        </StyledButton>
      ),
    };

    return _.map(store.ask.buttons, (o, i) => {
      if (typeof o === "string" && buttonsJSX[o]) return buttonsJSX[o];
      else {
        return o;
      }
    });
  }

  renderDialog() {
    return (
      <Box bgcolor={ColorX.GetColorCSS("darkBox")} padding={2} borderRadius={5} boxShadow={"0px 0px 2px 2px " + ColorX.GetColorCSS("elainOrange", 0.2)} minWidth={250}>
        <VStack width="100%">
          <HStack>
            <Box fontSize="large" fontWeight="bold" textAlign="left" width="100%" marginBottom={1}>
              {store.ask.title}
            </Box>
            <Spacer />
            {store.ask.showCloseIcon && (
              <StyledIconButton onClick={() => store.clearAsk()} width={24} theme={{ label: ColorX.GetColorCSS("elainOrange") }}>
                <Close />
              </StyledIconButton>
            )}
          </HStack>
          <Box fontSize="normal" textAlign="left" width="100%" marginY={1}>
            {store.ask.message}
          </Box>
          {store.ask.loading && (
            <StyledLinearProgress
              theme={{
                bar: ColorX.GetColorCSS("elainOrange", 0.5),
                background: ColorX.GetColorCSS("elainOrange", 0.2),
              }}
            />
          )}
          {store.ask.inner && store.ask.inner()}
          {store.ask.buttons && store.ask.buttons.length > 0 && <HStack marginTop={2}>{this.renderButtons()}</HStack>}
        </VStack>
      </Box>
    );
  }

  SnackDuration = () => {
    if (!store.alert || !store.alert.severity) return 3000;
    switch (store.alert.severity) {
      default:
      case "success":
        return 3000;
      case "warn":
      case "warning":
        return 6000;
      case "info":
        return 4000;
      case "error":
        return 6000;
    }
  };

  onMountChat3D = (callbacks) => {
    this.MountChat3D = callbacks;
    store.setChat3D(this.MountChat3D);
  };

  render() {
    let { snackOpen, loadingOpen, dialogOpen } = this.state;
    return (
      <Box className="container" height="100%" style={{ overflow: "scroll" }}>
        {/* <NavBar /> */}
        <HStack className="main-content" alignItems="flex-start">
          <Spacer />
          <HStack style={{ width: "100%", height: "fit-content" }}>{this.props.children}</HStack>
          <Spacer />
          <Chat3D onMounted={this.onMountChat3D} />
        </HStack>
        <Snackbar open={snackOpen} autoHideDuration={this.SnackDuration()} onClose={this.closeSnack}>
          <SnackAlert message={store.alert && store.alert.message} severity={store.alert && store.alert.severity} onClose={this.closeSnack} />
        </Snackbar>
        <Backdrop open={loadingOpen} style={{ zIndex: 500, color: ColorX.GetColorCSS("elainOrange") }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Backdrop open={dialogOpen} style={{ zIndex: 500, color: ColorX.GetColorCSS("elainOrange") }}>
          {this.renderDialog()}
        </Backdrop>

        <iframe
          id="mock-website"
          allowFullScreen="true"
          src="https://www.hk.cntaiping.com/ttc/"
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: "0",
            left: "0",
            border: "none",
          }}
        ></iframe>
      </Box>
    );
  }
}

export default withRouter(observer(Container));
