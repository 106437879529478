import React, { Component } from "react";
import Accessor from "__Static/Accessor";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import { Badge, Box, IconButton, Typography } from "@material-ui/core";
import ColorX from "__Static/ColorX";
import { DOMAIN } from "__Base/config";
import axios from "axios";
import store from "__Static/AppStore";
import _ from "lodash";
import { observer } from "mobx-react";
import { FavoriteBorderOutlined, Language, ShoppingCartOutlined } from "@material-ui/icons";
import { StyledButton } from "_Labizo/Stylizo";
import Redirect from "__Static/Redirect";
class NavBar extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      scrollY: 0,
    };
  }

  componentDidMount() {
    this._setAllStates(() => {
      this.getCategories();
      this.getProducts();
      this.getBrands();
    });
    window.addEventListener("scroll", this.updateScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(NavBar.defaultProps))) {
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
    window.removeEventListener("scroll", this.updateScroll);
  }

  _setAllStates = (callback) => {
    this.setState(
      (state, props) => ({
        ...props,
      }),
      callback
    );
  };

  updateScroll = () => {
    this.setState({ scrollY: window.scrollY });
  };

  getBrands = async () => {
    let url = DOMAIN + "/Tables/Brand/List";
    let payloadOut = {
      data: {},
    };
    try {
      let res = await axios.post(url, payloadOut);
      let { Success, payload } = res.data;

      if (Success) {
        let docs = payload.docs;
        store.setBrands(docs);
      } else {
        throw Error();
      }
    } catch (e) {
      setTimeout(() => {
        this.getBrands();
      }, 15000);
    }
  };

  getProducts = async () => {
    let url = DOMAIN + "/Tables/Product/List";
    let payloadOut = {
      data: {},
    };
    try {
      let res = await axios.post(url, payloadOut);
      let { Success, payload } = res.data;

      if (Success) {
        let docs = payload.docs;
        console.log(docs);
        store.setProducts(docs);
      } else {
        throw Error();
      }
    } catch (e) {
      setTimeout(() => {
        this.getProducts();
      }, 15000);
    }
  };

  getCategories = async () => {
    let url = DOMAIN + "/Tables/Category/List";
    let payloadOut = {
      data: {},
    };
    try {
      let res = await axios.post(url, payloadOut);
      let { Success, payload } = res.data;
      if (Success) {
        let docs = payload.docs;
        store.setCategories(docs);
      } else {
        throw Error();
      }
    } catch (e) {
      setTimeout(() => {
        this.getCategories();
      }, 15000);
    }
  };

  toggleLanguage = () => {
    store.setLang(store.lang === "EN" ? "TC" : "EN");
  };

  renderButton() {
    return _.map(store.categories, (o, i) => {
      return (
        <StyledButton
          onClick={() => Redirect.toCategory(o.code)}
          theme={{
            borderRadius: "0px",
            borderBottom: "2px solid transparent",
            hover: {
              background: "transparent",
              borderBottom: "2px solid " + "#ca3422",
            },
          }}
          key={i}
        >
          {o.name[store.lang]}
        </StyledButton>
      );
    });
  }

  renderMenu() {
    return <HStack spacing={10}>{this.renderButton()}</HStack>;
  }

  renderBadge(width = "15%") {
    return (
      <HStack spacing={6} width={width}>
        <Spacer />
        <IconButton size="small" onClick={() => Redirect.toFavourite()}>
          <Badge badgeContent={store.countFavourite()} color="primary">
            <FavoriteBorderOutlined />
          </Badge>
        </IconButton>
        <IconButton size="small" onClick={() => Redirect.toCart()}>
          <Badge badgeContent={store.countCart()} color="primary">
            <ShoppingCartOutlined />
          </Badge>
        </IconButton>
      </HStack>
    );
  }

  renderLanguage(width = "15%") {
    return (
      <HStack width={width}>
        <IconButton onClick={() => this.toggleLanguage()} size="small">
          <Badge badgeContent={store.lang === "EN" ? "EN" : "中"} color="primary">
            <Language />
          </Badge>
        </IconButton>
        <Spacer />
      </HStack>
    );
  }

  renderMain() {
    return (
      <VStack height="fit-content" spacing={1} paddingTop={1} width={"100%"}>
        <Spacer />
        {this.renderLogo()}
        <HStack>
          {this.renderLanguage()}
          {this.renderMenu()}
          <Spacer />
          {this.renderBadge()}
        </HStack>
        {store.getPage() === "home" && this.renderBanner()}
        <Spacer />
      </VStack>
    );
  }

  renderBanner() {
    return (
      <VStack width={"100%"} paddingBottom={1} style={{ overflow: "hidden" }}>
        <img src="Images/Banners/Burger01.png" alt="banner" style={{ width: "100%", objectFit: "contain" }} />
      </VStack>
    );
  }

  renderLogo(width = "fit-content") {
    return (
      <VStack
        height="fit-content"
        onClick={() => {
          Redirect.toHome();
        }}
        style={{ cursor: "pointer" }}
      >
        <Box height={"100%"}>
          <img src="Images/Logo/Logo_Mobinology.png" alt="elain" style={{ height: "100%", width: "100%" }} />
        </Box>
        {/* <Typography
          style={{
            fontFamily: "Prompt",
            color: ColorX.GetColorCSS("elainOrange"),
          }}
        >
          {"eShop"}
        </Typography> */}
      </VStack>
    );
  }

  renderNav() {
    let { scrollY } = this.state;
    if (scrollY < 100) return;
    return (
      <HStack
        height={50}
        style={{
          position: "fixed",
          background: ColorX.GetColorCSS("pureWhite"),
          zIndex: 2,
        }}
      >
        <Spacer width="50%" />
        {this.renderLogo()}
        {this.renderMenu()}
        <HStack width="15%">
          {this.renderLanguage(null)}
          {this.renderBadge(null)}
        </HStack>
        <Spacer width="45%" />
      </HStack>
    );
  }

  render() {
    return (
      <VStack width="100%" height={"fit-content"}>
        {this.renderMain()}
        {this.renderNav()}
      </VStack>
    );
  }
}

export default observer(NavBar);
