import _ from "lodash";
import Accessor from "./Accessor";
import store from "./AppStore";

class Authority {

  static AuthCheck = (authority, reqAuth) => {
    if(_.isEmpty(reqAuth) || Accessor.Get(authority, reqAuth) !== undefined){
      return true;
    }
    return false;
  }

  static LevelCheck = (level, reqLevel) => {
    return level <= reqLevel;
  }

  static FuncCheck = (authority, reqAuth, reqFunc) => {
    if(_.isEmpty(reqFunc)) return true;
    let func = Accessor.Get(authority, reqAuth);
    if(!func || !_.isArray(func)) return false;
    if(func.includes("*") || func.includes(reqFunc)) return true;
    return false;
  }

  static IsAccessible = (authority, level, reqAuth = "", reqLevel = 999, reqFunc = "") => {
    return this.AuthCheck(authority, reqAuth) 
      && this.LevelCheck(level, reqLevel) 
      && this.FuncCheck(authority, reqFunc);
  }

  static Require(reqAuth = "", reqLevel = 999, reqFunc = []){
    if(!this.IsAccessible(store.user.authority, store.user.level, reqAuth, reqLevel, reqFunc)){
      window.location.assign("/Denied");
    }
  }

}

export default Authority;