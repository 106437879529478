import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  InputLabel,
  DialogContentText,
} from "@material-ui/core";
import React, { Component } from "react";
import Accessor from "__Static/Accessor";

import MessageBox from "../MessageBox/MessageBox";
import IdleDoctor from "../Images/Avatar Doctor - Idle.svg";

import Grid from "@material-ui/core/Grid";
import axios from "axios";
import https from "https";
import "./HKIDform.css";
require("dotenv").config();

const url = process.env.REACT_APP_MW_PATIENT_ENDPOINT;
const email_EP = process.env.REACT_APP_EMAIL_ENDPOINT;

const styles = (theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      width: "80vw !important",
      height: "90vh !important",
    },
  },
  textField: {
    "& input": {
      fontSize: "150% !important",
    },
    "& label.Mui-focused": {
      fontSize: "2.0em",
    },
    "& label": {
      fontSize: "2.0em",
    },
  },
  FormLabel: {
    fontSize: "2.0em",
  },
  RadioGroup: {
    fontSize: "150% !important",
  },
  Checkbox: {
    fontSize: "150% !important",
  },
  FormControlLabel: {
    fontSize: "2.0em",
  },
});

class HKIDform extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.props.playAudio("/Audio/Page_HKIDform.mp3");

    this.state = {
      dialogOpen: false,
      formData: {
        HKID: "",
        Patient_Name: "",
        Patient_Eng_Name: "",
        Gender: "",
        dobDay: "",
        dobMonth: "",
        dobYear: "",
        opeDay: "",
        opeMonth: "",
        opeYear: "",
        injuredPartShoulder: false,
        injuredPartArm: false,
        injuredPartHand: false,
        injuredPartElbow: false,
        injuredPartFinger: false,
        injuredPartWrist: false,
        injuredPartOther: false,
        Injured_Side: "",
        Dominant: "",
        PopUpAlert: false,
        AlertMessage: "",
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Only update state if the HKIDContent has changed
    if (nextProps.HKIDContent !== prevState.HKIDContent) {
      let content = nextProps.HKIDContent;
      let newFormData = { ...prevState.formData };

      for (let key in content) {
        if (key === "ID number") {
          newFormData.HKID = content[key];
        }
        if (key === "Chinese name") {
          newFormData.Patient_Name = content[key];
        }
        if (key === "English name") {
          newFormData.Patient_Eng_Name = content[key];
        }
        if (key === "Sex") {
          let genderValue = content[key];
          if (genderValue === "M") {
            genderValue = "男";
          } else if (genderValue === "F") {
            genderValue = "女";
          }
          newFormData.Gender = genderValue;
        }
        if (key === "Date of birth") {
          let dob = new Date(content[key]);
          newFormData.dobYear = dob.getFullYear();
          newFormData.dobMonth = dob.getMonth() + 1; //month start from 0
          newFormData.dobDay = dob.getDate();
        }
      }

      return { formData: newFormData, HKIDContent: nextProps.HKIDContent };
    }

    return null; // Return null if no changes
  }

  componentDidMount() {
    this.timeoutId = setTimeout(this.handleDialogOpen, 3000);
  }

  handleInputChange = (event) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [event.target.name]: event.target.value,
      },
    }));
    this.secondCheck();
  };

  //Check again register record for registered patient scanning error
  secondCheck() {
    let InputData = {
      session_id: "patient",
      user_id: "syva",
      input: {
        type: "text",
        method: "Check_HKID",
        content: {
          HKID: this.state.formData.HKID,
        },
      },
    };

    axios
      .post(url, InputData)
      .then((response) => {
        if (response.data.Success == true) {
          this.props.setPatientID(this.state.InputData.input.content.HKID);
          this.props.setName(this.state.formData.Patient_Name);
          this.nextPage();
        }
      })
      .catch((error) => {
        //this.handleAlertOpen("伺服器連線異常，請聯絡職員！！");
        let subject = "HKU Server Connection Error";
        let message = "Error appears in checking ID, please check mw.";
        this.postEmail(subject, message);
        console.error(error);
      });
  }

  handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  handleDialogClose = () => {
    // Manual validation for the required fields
    if (
      this.state.formData.HKID &&
      // this.state.formData.Patient_Name &&
      this.state.formData.Gender &&
      this.state.formData.dobDay &&
      this.state.formData.dobMonth &&
      this.state.formData.dobYear
      // this.state.formData.opeDay &&
      // this.state.formData.opeMonth &&
      // this.state.formData.opeYear &&
      // (this.state.formData.injuredPartShoulder ||
      //   this.state.formData.injuredPartArm ||
      //   this.state.formData.injuredPartHand ||
      //   this.state.formData.injuredPartElbow ||
      //   this.state.formData.injuredPartFinger ||
      //   this.state.formData.injuredPartWrist ||
      //   this.state.formData.injuredPartOther) &&
      // this.state.formData.Injured_Side &&
      // this.state.formData.Dominant
    ) {
      this.CreateNewPatient();
    } else {
      this.handleAlertOpen(
        "掃瞄資料有缺漏，請重新掃瞄。 試將身份證放至較入的位置"
      );
    }
  };

  handleCheckboxChange = (event) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.setState = (state, callback) => {
      return;
    };
  }

  nextPage() {
    this.props.changePageStatus("Start");
  }

  CreateNewPatient() {
    let agent = new https.Agent({
      rejectUnauthorized: false,
    });

    let Input = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "Add",
        content: this.state.formData,
      },
    };

    axios
      .post(url, Input)
      .then((response) => {
        if (response.data.Success == false) {
          this.handleAlertOpen("註冊失敗，請退出重試或聯絡職員！！");
          let subject = "HKU patient register Error";
          let message =
            "Error appeared in new patient register, please check cms and db.";
          this.postEmail(subject, message);
        } else if (response.data.Success == true) {
          this.props.setHKID(this.state.formData.HKID);
          this.props.setName(this.state.formData.Patient_Name);
          this.setState({
            dialogOpen: false,
          });
          this.nextPage();
        }
      })
      .catch((error) => {
        this.handleAlertOpen("伺服器連線異常，請聯絡職員！！");
        let subject = "HKU Server Connection Error";
        let message = "Error appeared in new HKID register, please check mw.";
        this.postEmail(subject, message);
        console.error(error);
      });
  }

  handleAlertOpen = (message) => {
    this.setState({
      PopUpAlert: true,
      AlertMessage: message,
    });
  };

  handleAlertClose = () => {
    this.setState({
      PopUpAlert: false,
    });
  };

  postEmail(subject, message) {
    let Input = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "report",
        content: {
          subject: subject,
          message: message,
        },
      },
    };

    axios
      .post(email_EP, Input)
      .then((response) => {
        if (response.data.Success == false) {
          let Input = {
            session_id: "patient",
            user_id: "svya",
            input: {
              type: "text",
              method: "save_log",
              content: {
                subject: subject,
                message: message,
              },
            },
          };

          axios
            .post(url, Input)
            .then((response) => {
              if (response.data.Success == false) {
                console.error("Email and server all down.");
              }
            })
            .catch((error) => {
              console.error("Email and server all down.");
            });
        }
      })
      .catch((error) => {
        let Input = {
          session_id: "patient",
          user_id: "svya",
          input: {
            type: "text",
            method: "save_log",
            content: {
              subject: subject,
              message: message,
            },
          },
        };

        axios
          .post(url, Input)
          .then((response) => {
            if (response.data.Success == false) {
              console.error("Email and server all down.");
            }
          })
          .catch((error) => {
            console.error("Email and server all down.");
          });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div class="portal">
        <div class="HKUBackground">
          <div class="HKUWithDoctor">
            <MessageBox
              location="top"
              text="您嘅身份證未登記！麻煩您輸入以下資料登記！"
              lines="1"
            ></MessageBox>
            <Box className="QRDoctor">
              <img src={IdleDoctor}></img>
            </Box>
          </div>
        </div>
        <div class="HKUButton">
          <button
            class="buttonReset EnterInfo"
            onClick={this.handleDialogOpen}
          ></button>
        </div>
        <Dialog
          className="Alert2"
          open={this.state.PopUpAlert}
          onClose={this.handleAlertClose}
          onBackdropClick={this.handleAlertClose}
        >
          <DialogContent>
            <DialogContentText>{this.state.AlertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="form-dialog-title"
          className={classes.dialog}
        >
          <DialogContentText
            id="form-dialog-title"
            style={{
              textAlign: "center",
              fontSize: "3em",
              color: "black",
              paddingTop: "20px",
            }}
          >
            請輸入以下資料
          </DialogContentText>
          <DialogContentText style={{ textAlign: "center", fontSize: "3em" }}>
            若掃瞄資料有誤，請重新掃瞄
          </DialogContentText>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="HKID"
              label="身份證號碼"
              type="text"
              fullWidth
              value={this.state.formData.HKID}
              onChange={this.handleInputChange}
              className={classes.textField}
              required
              placeholder="Y123456(7)"
              inputProps={{
                style: {
                  color: this.state.formData.HKID ? "black" : "grey",
                },
              }}
            />
            {/* <TextField
              margin="dense"
              name="Patient_Name"
              label="病人姓名"
              type="text"
              fullWidth
              value={this.state.formData.Patient_Name}
              onChange={this.handleInputChange}
              className={classes.textField}
              required
            /> */}

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormLabel style={{ fontSize: "2em" }}>性別 *</FormLabel>
              </Grid>
              <Grid item xs={21} sm={3}>
                <RadioGroup
                  name="Gender"
                  value={this.state.formData.Gender}
                  onChange={this.handleInputChange}
                  row
                >
                  <FormControlLabel
                    value="男"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>男</Typography>
                    }
                  />
                  <FormControlLabel
                    value="女"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>女</Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormLabel style={{ fontSize: "2em" }}>出生日期 *</FormLabel>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="dobDay-label" style={{ fontSize: "2em" }}>
                    日期
                  </InputLabel>
                  <Select
                    value={this.state.formData.dobDay || ""}
                    onChange={this.handleInputChange}
                    name="dobDay"
                    style={{ fontSize: "2em" }}
                    labelId="dobDay-label"
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <MenuItem
                        key={i + 1}
                        value={i + 1}
                        style={{ fontSize: "2em" }}
                      >
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="dobMonth-label" style={{ fontSize: "2em" }}>
                    月份
                  </InputLabel>
                  <Select
                    value={this.state.formData.dobMonth || ""}
                    onChange={this.handleInputChange}
                    name="dobMonth"
                    style={{ fontSize: "2em" }}
                    labelId="dobMonth-label"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <MenuItem
                        key={i + 1}
                        value={i + 1}
                        style={{ fontSize: "2em" }}
                      >
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="dobYear-label" style={{ fontSize: "2em" }}>
                    年份
                  </InputLabel>
                  <Select
                    value={this.state.formData.dobYear || ""}
                    onChange={this.handleInputChange}
                    name="dobYear"
                    style={{ fontSize: "2em" }}
                    labelId="dobYear-label"
                  >
                    {Array.from({ length: 124 }, (_, i) => (
                      <MenuItem
                        key={2023 - i}
                        value={2023 - i}
                        style={{ fontSize: "2em" }}
                      >
                        {2023 - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormLabel style={{ fontSize: "2em" }}>受傷日期</FormLabel>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="opeDay-label" style={{ fontSize: "2em" }}>
                    日期
                  </InputLabel>
                  <Select
                    value={this.state.formData.opeDay || ""}
                    onChange={this.handleInputChange}
                    name="opeDay"
                    style={{ fontSize: "2em" }}
                    labelId="opeDay-label"
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <MenuItem
                        key={i + 1}
                        value={i + 1}
                        style={{ fontSize: "2em" }}
                      >
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="opeMonth-label" style={{ fontSize: "2em" }}>
                    月份
                  </InputLabel>
                  <Select
                    value={this.state.formData.opeMonth || ""}
                    onChange={this.handleInputChange}
                    name="opeMonth"
                    style={{ fontSize: "2em" }}
                    labelId="opeMonth-label"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <MenuItem
                        key={i + 1}
                        value={i + 1}
                        style={{ fontSize: "2em" }}
                      >
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="opeYear-label" style={{ fontSize: "2em" }}>
                    年份
                  </InputLabel>
                  <Select
                    value={this.state.formData.opeYear || ""}
                    onChange={this.handleInputChange}
                    name="opeYear"
                    style={{ fontSize: "2em" }}
                    labelId="opeYear-label"
                  >
                    {Array.from({ length: 124 }, (_, i) => (
                      <MenuItem
                        key={2023 - i}
                        value={2023 - i}
                        style={{ fontSize: "2em" }}
                      >
                        {2023 - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid> */}

            <Grid container spacing={6} alignItems="center">
              <Grid item xs={3}>
                <FormLabel style={{ fontSize: "2em" }}>受傷部位</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartShoulder"
                      checked={this.state.formData.injuredPartShoulder || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>肩膊</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartArm"
                      checked={this.state.formData.injuredPartArm || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>手臂</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartHand"
                      checked={this.state.formData.injuredPartHand || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>手部</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                {/* empty */}
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartWrist"
                      checked={this.state.formData.injuredPartWrist || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>手腕</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartElbow"
                      checked={this.state.formData.injuredPartElbow || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>手肘</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartFinger"
                      checked={this.state.formData.injuredPartFinger || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>手指</Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                {/* empty */}
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="injuredPartOther"
                      checked={this.state.formData.injuredPartOther || false}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "2em" }}>其他</Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormLabel style={{ fontSize: "2em" }}>受傷側</FormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <RadioGroup
                  name="Injured_Side"
                  value={this.state.formData.Injured_Side}
                  onChange={this.handleInputChange}
                  row
                >
                  <FormControlLabel
                    value="左邊"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>左邊</Typography>
                    }
                  />
                  <FormControlLabel
                    value="右邊"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>右邊</Typography>
                    }
                  />
                  <FormControlLabel
                    value="兩邊"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>兩邊</Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormLabel style={{ fontSize: "2em" }}>慣用手</FormLabel>
              </Grid>
              <Grid item xs={12} sm={9}>
                <RadioGroup
                  name="Dominant"
                  value={this.state.formData.Dominant}
                  onChange={this.handleInputChange}
                  row
                >
                  <FormControlLabel
                    value="左手"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>左手</Typography>
                    }
                  />
                  <FormControlLabel
                    value="右手"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>右手</Typography>
                    }
                  />
                  <FormControlLabel
                    value="兩邊"
                    control={<Radio />}
                    label={
                      <Typography style={{ fontSize: "2em" }}>兩邊</Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <button
              class="buttonReset FinishInfo"
              onClick={this.handleDialogClose}
              style={{ margin: "0 auto" }}
            ></button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(HKIDform);
