import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Accessor from '__Static/Accessor';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';

import store from '__Static/AppStore';
import { StyledButton } from '_Labizo/Stylizo';
import { ArrowBackIos } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import ColorX from '__Static/ColorX';
import Redirect from '__Static/Redirect';
import ItemRow from './ItemRow';

import _ from 'lodash';

class Cart extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(Cart.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  renderToPayment(){
    let bill = {
      EN: "Checkout",
      TC: "結算"
    };
    return (
      <HStack>
        <StyledButton
          disabled={store.countCart() <= 0}
          onClick={() => { Redirect.toPayment(); }}
          theme={{
            width: "100%",
            borderRadius: "0px",
            background: ColorX.GetColorCSS("elainOrange"),
            color: ColorX.GetColorCSS("pureWhite"),
            hover: {
              background: ColorX.GetColorCSS("elainOrangeDark")
            },
            disabled: {
              background: ColorX.GetColorCSS("lightGrey")
            }
          }}>
          {bill[store.lang]}
        </StyledButton>
      </HStack>
    );
  }

  renderSummary(){
    let summary = {
      EN: "Summary",
      TC: "結算詳情"
    };
    let totalLabel = {
      EN: "Total",
      TC: "總額"
    };
    let qtyLabel = {
      EN: "Item Count",
      TC: "貨品件數"
    };
    let sum = 0;
    let tqty = 0;
    _.map(store.cart, (o, i) => {
      let doc = store.getProductDoc(i);
      sum += o.qty * doc.price;
      tqty += o.qty;
    });

    let itemStyle = {
      padding: "5px 10px"
    };

    return(
      <VStack width="40%" 
        height="fit-content"
        style={{
          background: ColorX.GetColorCSS("lightGrey", 0.5),
          margin: "0 50px"
          }}>
        <HStack paddingY={1}
          style={{
            borderBottom: "1px solid " + ColorX.GetColorCSS("grey")
            }}>
          <Typography>
            {summary[store.lang]}
          </Typography>
        </HStack>
        <VStack width="100%">
          <HStack>
            <Typography style={itemStyle}>
              {qtyLabel[store.lang]}
            </Typography>
            <Spacer/>
            <Typography style={itemStyle}>
              {tqty}
            </Typography>
          </HStack>
          <HStack>
            <Typography style={itemStyle}>
              {totalLabel[store.lang]}
            </Typography>
            <Spacer/>
            <Typography style={{
              ...itemStyle,
              color: ColorX.GetColorCSS("red"),
              fontWeight: "bold",
              fontSize: 18
            }}>
              {"HKD$ " + sum}
            </Typography>
          </HStack>
        </VStack>
        {this.renderToPayment()}
      </VStack>
    );
  }

  renderRows(){
    return _.map(store.cart, (o, i) => {
      return <ItemRow pid={i} key={i}/>;
    });
  }

  renderTableHeader(){
    let items = {
      EN: "Items",
      TC: "商品"
    };

    let qty = {
      EN: "Quantity",
      TC: "數量"
    };

    let total = {
      EN: "Total",
      TC: "總額"
    };
    return (
      <HStack width="100%">
        <HStack width="50%"
          style={{
            borderBottom: "1px solid " + ColorX.GetColorCSS("lightGrey"),
            margin: "0 10px"
          }}>
          <Typography>
            {items[store.lang]}
          </Typography>
          <Spacer/>
        </HStack>
        <HStack width="20%"
          style={{
            borderBottom: "1px solid " + ColorX.GetColorCSS("lightGrey"),
            margin: "0 10px"
          }}>
          <Spacer/>
          <Typography>
            {qty[store.lang]}
          </Typography>
          <Spacer/>
        </HStack>
        <HStack width="30%"
          style={{
            borderBottom: "1px solid " + ColorX.GetColorCSS("lightGrey"),
            margin: "0 10px"
          }}>
          <Spacer/>
          <Typography>
            {total[store.lang]}
          </Typography>
        </HStack>
      </HStack>
    );
  }

  renderTable(){
    return (
      <VStack width="60%">
        {this.renderTableHeader()}
        {this.renderRows()}
      </VStack>
    );
  }

  renderTitle(){
    let cart = {
      EN: "Cart (Total @count items)",
      TC: "購物車 (共 @count 件貨品)"
    };
    let str = cart[store.lang].replace("@count", store.countCart());
    return (
      <HStack width="98%" paddingTop={1}>
        <Typography style={{fontSize: 24}}>
          {str}
        </Typography>
        <Spacer/>
      </HStack>
    );
  }

  renderContinue(){
    let cont = {
      EN: "Continue Shopping",
      TC: "繼續購物"
    };
    return (
      <HStack>
        <StyledButton 
          onClick={() => { Redirect.toHome(); }}
          theme={{
            background: ColorX.GetColorCSS("transparent"),
            color: ColorX.GetColorCSS("greyOut"),
            hover: {
              background: ColorX.GetColorCSS("transparent"),
              color: ColorX.GetColorCSS("grey"),
            }
          }}>
          <ArrowBackIos/>
          <Typography>
            {cont[store.lang]}
          </Typography>
        </StyledButton>
        <Spacer/>
      </HStack>
    );
  }

  renderInside(){
    if(store.countCart() > 0){
      return (
        <HStack alignItems="flex-start">
          {this.renderTable()}
          {this.renderSummary()}
        </HStack>
      );
    }else{
      let toShopping = {
        EN: "Go Shopping",
        TC: "再逛一逛~"
      };
      let noItem = {
        EN: "There is no items in your cart.",
        TC: "你的購物車沒有物品。"
      }
      return (
        <VStack height="100%" spacing={20}>
          <Spacer/>
          <HStack style={{width: 100, height: 100}}>
            <img src="Images/empty-cart.svg" alt="empty-cart" style={{width: "100%"}}/>
          </HStack>
          <Typography>
            {noItem[store.lang]}
          </Typography>
          <StyledButton onClick={() => Redirect.toHome()} 
            theme={{
              width: "100%",
              background: ColorX.GetColorCSS("elainOrange"),
              color: ColorX.GetColorCSS("pureWhite"),
              hover: {
                background: ColorX.GetColorCSS("elainOrangeDark")
              }
            }}>
            {toShopping[store.lang]}
          </StyledButton>
          <Spacer/>
        </VStack>
      );
    }
  }

  render(){
    return (
      <VStack width="80%" spacing={10}>
        {this.renderContinue()}
        {this.renderTitle()}
        {this.renderInside()}
      </VStack>  
    );
  }

}

export default observer(Cart);
