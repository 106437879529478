import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  colors,
} from "@material-ui/core";
import React, { Component } from "react";
import Accessor from "__Static/Accessor";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MessageBox from "../MessageBox/MessageBox";
import IdleDoctor from "../Images/Avatar Doctor - Idle.svg";
import HKIDDoctor from "../Images/Avatar Doctor - HKID.png";
import ResultBar from "../Images/Result Score Bar.svg";
import "./Result.css";
import https from "https";
import axios from "axios";
import QRcode from "qrcode.react";
require("dotenv").config();

const url = process.env.REACT_APP_MW_PATIENT_ENDPOINT;
const email_EP = process.env.REACT_APP_EMAIL_ENDPOINT;

class Result extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      timeString: "01:10",
      seconds: 70,
      counting: false,
      Name: "",
      canQuit: false,
      PopUpAlert: false,
      AlertType: "",
      AlertMessage: "",
      pauseCounting: false,
      scoreUpdate: false,
      reprintCounter: 0,
      postpayload: {},
      score: Math.round(
        (Object.values(this.props.questionnaire).reduce((a, b) => a + b, 0) /
          11 -
          1) *
          25
      ),
    };

    this.timeoutID = null;

    this.props.playAudio(
      "/Audio/Page_Result.mp3",
      "/Audio/score/" + this.state.score + ".mp3"
    );
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));

    setTimeout(() => {
      // print function here
      this.UpdateDB(this.state.score);
      if (this.state.counting == false) {
        this.setState({
          counting: true,
        });
        this.countDown();
      }
    }, 1000);
  }

  countDown() {
    if (this.state.pauseCounting == true) {
      return;
    }
    if (this.state.seconds > 0) {
      this.setState({
        seconds: this.state.seconds - 1,
        timeString: this.getTimeString(this.state.seconds - 1),
      });

      if (this.state.seconds <= 60) {
        this.setState({
          canQuit: true,
        });
      }
    } else {
      this.props.endConversation();
      return;
    }
    setTimeout(() => {
      this.countDown();
    }, 1000);
  }

  getTimeString(time) {
    let minute = Math.floor(time / 60);
    let seconds = time % 60;
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minute + ":" + seconds;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Accessor.IsIdentical(
        prevProps,
        this.props,
        Object.keys(Result.defaultProps)
      )
    ) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  renderTopBar() {
    return (
      <div className="resultTopBar">
        <span>
          問卷已經作答完畢，請確認離開。問卷將會係 {this.state.timeString}{" "}
          後結束關閉
        </span>
      </div>
    );
  }

  renderBottomBar() {
    return (
      <div className="resultBottomBar">
        <div style={{ position: "relative", width: "100%", height: "40px" }}>
          <img
            src={ResultBar}
            id="endResultBar"
            style={{ width: "150%", height: "150%" }}
          />
          <span
            id="endResultScore"
            style={{
              position: "absolute",
              left:
                this.state.score < 10
                  ? `${this.state.score - 1.5}%`
                  : `${this.state.score - 3}%`,
            }}
          >
            {this.state.score}
          </span>
          <ArrowDropDownIcon
            id="endResultArrow"
            style={{
              position: "absolute",
              left: `calc(${this.state.score}%)`,
              width: "750%",
              height: "850%",
            }}
          />
        </div>
        {this.state.canQuit === true ? (
          <button
            className="buttonReset endResultButton"
            onClick={() => {
              this.props.endConversation();
            }}
          ></button>
        ) : (
          <span style={{ color: "#005f73", fontSize: "3em" }}>列印中...</span>
        )}
      </div>
    );
  }

  UpdateDB(score) {
    let agent = new https.Agent({
      rejectUnauthorized: false,
    });

    let id = "";
    if (this.props.PatientID != "") id = this.props.PatientID;
    else if (this.props.HKID != "") id = this.props.HKID;

    let Input = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "Update",
        content: {
          _id: id,
          score: score,
          question_score: this.props.questionnaire,
          questionTimeString: this.props.questionTimeString,
          questionStartTime: this.props.questionStartTime,
        },
      },
    };
    axios
      .post(url, Input)
      .then((response) => {
        if (response.data.Success == false) {
          this.handleAlertOpen("問卷紀錄更新失敗，請再試一次或聯絡職員！");
          let subject = "HKU Questionnaire record update failed";
          let message =
            "The patient record data update has failed, the reponse.data.success == false. Please check the mw state.";
          this.postEmail(subject, message);
        } else if (response.data.Success == true) {
          this.setState({
            postpayload: response.data.payload,
            scoreUpdate: true,
          });
          this.postData(score, response.data.payload);
        }
      })
      .catch((error) => {
        this.handleAlertOpen("伺服器連線異常，請聯絡職員！");
        let subject = "HKU Questionnaire caugh error when updating score";
        let message =
          "The POST request encountered an error when updating patient score record data. Please check the state of the MW.";
        this.postEmail(subject, message);
        console.error(error);
      });
  }

  postEmail(subject, message) {
    let Input = {
      session_id: "patient",
      user_id: "svya",
      input: {
        type: "text",
        method: "report",
        content: {
          subject: subject,
          message: message,
        },
      },
    };

    axios
      .post(email_EP, Input)
      .then((response) => {
        if (response.data.Success == false) {
          let Input = {
            session_id: "patient",
            user_id: "svya",
            input: {
              type: "text",
              method: "save_log",
              content: {
                subject: subject,
                message: message,
              },
            },
          };

          axios
            .post(url, Input)
            .then((response) => {
              if (response.data.Success == false) {
                console.error("Email and server all down.");
              }
            })
            .catch((error) => {
              console.error("Email and server all down.");
            });
        }
      })
      .catch((error) => {
        let Input = {
          session_id: "patient",
          user_id: "svya",
          input: {
            type: "text",
            method: "save_log",
            content: {
              subject: subject,
              message: message,
            },
          },
        };

        axios
          .post(url, Input)
          .then((response) => {
            if (response.data.Success == false) {
              console.error("Email and server all down.");
            }
          })
          .catch((error) => {
            console.error("Email and server all down.");
          });
      });
  }

  postData(score, payload) {
    try {
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });

      return new Promise((resolve, reject) => {
        const data = {
          ID: "",
          Name: payload.Patient_Name,
          Score: score,
          URL: "https://hku.syva.ai/cms/Patient?ID=" + payload._id,
          QRContent: "qDASH Score = " + score,
        };

        const dataString = JSON.stringify(data);

        const options = {
          hostname: "internal-printer.lan",
          port: 5000,
          path: "/print",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Content-Length": dataString.length,
          },
          rejectUnauthorized: false,
        };

        const req = https.request(options, (res) => {
          res.setEncoding("utf8");
          let responseBody = "";

          res.on("data", (chunk) => {
            responseBody += chunk;
          });

          res.on("end", () => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              resolve();
              this.setState({ canQuit: true });
              const responseMessage = JSON.parse(responseBody).message;
              if (
                responseMessage === "No paper in the printer" ||
                responseMessage === "Paper is ending soon."
              ) {
                this.handleAlertOpen(
                  "打印機紙張即將或已經用完，請聯絡職員補充！",
                  "QR"
                );
                let subject = "HKU Printer is out of paper";
                let message =
                  "The printer paper is running low or has already run out. Please refill.";
                this.postEmail(subject, message);
              }
            } else {
              if (this.state.reprintCounter >= 2) {
                this.handleAlertOpen("QR碼列印失敗。請聯絡職員！", "QR");
                let subject = "HKU Printer failed printing";
                let message =
                  "The printer has encountered an error. The reason maybe related to data format error.The status Code is :" +
                  res.statusCode;
                this.postEmail(subject, message);
                reject(`Error: ${responseBody}`);
              } else {
                this.handleAlertOpen("QR碼列印失敗。請重試！", "reprint");
                reject(`Error: ${responseBody}`);
              }
            }
          });
        });

        req.on("error", (e) => {
          if (this.state.reprintCounter >= 2) {
            console.error(`problem with request: ${e.message}`);
            reject(e.message);
            this.handleAlertOpen("打印機連線錯誤，請聯絡職員！", "QR");
            let subject = "HKU Printer Connection Error";
            let message =
              "The printing request was caught error, printer off line.";
            this.postEmail(subject, message);
          } else {
            console.error(`problem with request: ${e.message}`);
            reject(e.message);
            this.handleAlertOpen("打印機連線錯誤，請重試！", "reprint");
          }
        });
        req.write(dataString);
        req.end();
      });
    } catch (e) {
      if (this.state.reprintCounter >= 2) {
        console.error("Error in postData:", e);
        this.handleAlertOpen("打印機異常，請聯絡職員!", "QR");
        let subject = "HKU Printer Connection Error";
        let message =
          "The printing function encountered an error error, please check error log:" +
          e;
        this.postEmail(subject, message);
      } else {
        console.error("Error in postData:", e);
        this.handleAlertOpen("打印機異常，請聯絡職員!", "reprint");
      }
    }
  }

  renderQR() {
    let name = "";
    if (this.props.Name !== "") name = this.props.Name;

    let id = "";
    if (this.props.PatientID !== "") id = this.props.PatientID;

    const url = "https://hku.syva.ai/cms/Patient?ID=" + id;

    const date = new Date();
    const dateTimeNow = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "0.8em",
            textAlign: "left",
            marginBottom: "20px",
            color: "black",
            padding: 30,
          }}
        >
          您亦可以影低QR碼交俾醫生:
          <br />
          <br />
          日期: {dateTimeNow}
          <br />
          姓名: {name}
          <br />
          分數: {this.state.score}
        </div>
        <QRcode value={url} size={180} />
      </div>
    );
  }

  handleAlertOpen = (message, type) => {
    switch (type) {
      case "QR":
        this.setState({
          AlertType: "AlertQR",
          PopUpAlert: true,
          AlertMessage: (
            <div>
              {message}
              {this.renderQR()}
            </div>
          ),
          pauseCounting: true,
        });
        break;
      case "alert":
        this.setState({
          AlertType: "Alert",
          PopUpAlert: true,
          AlertMessage: message,
          pauseCounting: true,
        });
        break;
      case "reprint":
        this.setState({
          AlertType: "reprint",
          PopUpAlert: true,
          AlertMessage: <div>{message}</div>,
          pauseCounting: true,
        });
      default:
        this.setState({
          AlertType: "reprint",
          PopUpAlert: true,
          AlertMessage: <div>{message}</div>,
          pauseCounting: true,
        });
    }
  };

  handleAlertClose = () => {
    this.setState(
      {
        PopUpAlert: false,
        pauseCounting: false,
      },
      () => {
        this.countDown();
      }
    );
  };

  renderDialog() {
    let className;
    switch (this.state.AlertType) {
      case "Alert":
        className = "Alert";
        break;
      case "AlertQR":
        className = "AlertQR";
        break;
      case "reprint":
        className = "reprint";
        break;
      default:
        className = "Alert";
    }

    if (className === "reprint") {
      return (
        <Dialog
          className={className}
          open={this.state.PopUpAlert}
          onClose={this.handleAlertClose}
          onBackdropClick={this.handleAlertClose}
        >
          <DialogContent>
            <DialogContentText>{this.state.AlertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <button
              className="buttonReset endResultButton2"
              onClick={() => {
                this.setState({
                  reprintCounter: this.state.reprintCounter + 1,
                });
                if (this.state.scoreUpdate === false) {
                  this.UpdateDB(this.state.score);
                } else {
                  this.postData(this.state.score, this.state.postpayload);
                }
                this.handleAlertClose();
              }}
            ></button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          className={className}
          open={this.state.PopUpAlert}
          onClose={this.handleAlertClose}
          onBackdropClick={this.handleAlertClose}
        >
          <DialogContent>
            <DialogContentText>{this.state.AlertMessage}</DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      );
    }
  }

  render() {
    let message = `唔該晒，問題已經答完，提提您要記得帶走身份證！<br/>請將列印出黎嘅<span className='blueText'>診斷評分報告</span>交俾您嘅<span className='blueText'>診症醫生</span>作進一步評估。以下係您嘅診斷評分：${this.state.score} 分 `;
    return (
      <div className="portal">
        <div className="HKUBackground">
          {this.renderTopBar()}
          <div className="HKUWithDoctor">
            <MessageBox location="top" text={message} lines="2"></MessageBox>
            <Box className="QRDoctor">
              <img src={HKIDDoctor}></img>
            </Box>
          </div>
        </div>
        <div className="HKUButton">{this.renderBottomBar()}</div>
        {this.renderDialog()}
      </div>
    );
  }
}

export default Result;
