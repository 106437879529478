export const StartDate = process.env.REACT_APP_STARTDATE || "2021-02";

export const _domain = {
  demo: "https://demo02.mobinology.com/eshop_be",
  test: "http://localhost:7654",
};

export const DOMAIN = process.env.NODE_ENV === "production" ? _domain[process.env.REACT_APP_STAGE] : _domain.test;

const CMS = {
  demo: "https://demo02.mobinology.com/cms/be/",
  test: "https://demo.mobinology.com/cms/be/",
};

export const CMSDOMAIN = process.env.NODE_ENV === "production" ? CMS[process.env.REACT_APP_STAGE] : CMS.test;

export const Chatbot = {
  user: {
    _id: 2,
    name: "Elain",
    avatar: "https://placeimg.com/140/140/any",
  },
  interval: 3000,
  version: "1.0.0",
  channel: "Talk2Elain",
  domain: {
    demo: "https://demo02.mobinology.com/mw",
    test: "http://localhost:5034",
  },
};

export const ChatDOMAIN = process.env.NODE_ENV === "production" ? Chatbot.domain[process.env.REACT_APP_STAGE] : Chatbot.domain.test;

export const SiteDOMAIN = "HK-";

export const VoiceMW = process.env.VOICE_MW || "https://demo02.mobinology.com/voice/"

export const SYVAMW = "https://demo02.mobinology.com/mw/Talk2Elain"