const QuestionList = [
  {
    question: "Question 0 place holder",
    lines: 1,
  },
  {
    question:
      "以下落嚟，請使用下面五個按鈕回答問題，選擇一個最適合你嘅答案。<br>過去呢個星期，<span class='redText'>扭開個緊或新嘅樽蓋</span>有幾困難？ ",
    lines: 2,
  },
  {
    question:
      "過去呢個星期，做消耗⼤量體⼒嘅<span class='redText'>家務</span>(例如：<span class='redText'>抹窗</span>或<span class='redText'>拖地</span>有幾困難？) ",
    lines: 1,
  },
  {
    question:
      "過去呢個星期，拎<span class='redText'>購物袋</span>或<span class='redText'>公事包</span>有幾困難？",
    lines: 1,
  },
  {
    question: "過去呢個星期，<span class='redText'>洗背脊</span>有幾困難？",
    lines: 1,
  },
  {
    question: "過去呢個星期，<span class='redText'>拎刀切嘢食</span>有幾困難？",
    lines: 1,
  },
  {
    question:
      "過去呢個星期，進⾏⼀啲需要<span class='redText'>上肢發⼒</span>嘅<span class='redText'>休閒活動</span>有幾困難？</br>就例如：依家要你做運動，打排球，打網球、或者拎支錘仔揼嘢。 ",
    lines: 2,
  },
  {
    question:
      "過去呢個星期，你<span class='redText'>⼿部嘅傷勢</span>對你<span class='redText'>正常社交活動</span>造成幾大影響？",
    lines: 1,
  },
  {
    question:
      "過去呢個星期，你<span class='redText'>⼿部嘅傷勢</span>對你<span class='redText'>工作</span>或者<span class='redText'>日常活動</span>造成幾大影響？ ",
    lines: 1,
  },
  {
    question: "過去呢個星期，隻<span class='redText'>⼿嘅痛楚</span>有幾嚴重？",
    lines: 1,
  },
  {
    question:
      "過去呢個星期，隻<span class='redText'>⼿</span>俾<span class='redText'>針咭</span>嘅感覺有幾嚴重？",
    lines: 1,
  },
  {
    question:
      "過去呢個星期，因為隻<span class='redText'>⼿</span>嘅痛楚，對你<span class='redText'>瞓教</span>造成幾大困難？ ",
    lines: 1,
  },
];

export default QuestionList;
