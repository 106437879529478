import { Typography, Box } from '@material-ui/core';
import React, { Component } from 'react';
import { HStack, Spacer, VStack } from '_Labizo/Stackizo';
import Accessor from '__Static/Accessor';

import htmlParser from "html-react-parser";

class MessageBox extends Component {

  static propTypes = {
    location: String,
    text: String,
    lines: Number
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!Accessor.IsIdentical(prevProps, this.props, Object.keys(MessageBox.defaultProps))) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  getBoxClass() {
    return this.props.location == "top" ? "messageBar topMessageBar" : "messageBar bottomMessageBar";
  }

  getMessageClass() {
    return this.props.lines == 2 ? "twoLineMessage messageBoxMessage" : "oneLineMessage messageBoxMessage"
  }

  renderText() {
    let split = this.props.text.split("<br/>");
    let output = []
    split.forEach((element) => {
      let out = htmlParser(element);
      output.push(<span class={this.getMessageClass()}>
        {out}
      </span>)
    });
    return output;
  }

  render() {
    return (
      <Box class={this.getBoxClass()}>
        <div class="messageBox">
          {this.renderText()}
        </div>
      </Box>
    );
  }

}

export default MessageBox;
