import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";
import { HStack, Spacer, VStack } from "_Labizo/Stackizo";
import Accessor from "__Static/Accessor";
import FastForwardIcon from "@material-ui/icons/FastForward";
import MessageBox from "../MessageBox/MessageBox";
import IdleDoctor from "../Images/Avatar Doctor - Idle.svg";

class Start extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      voiceRecord: false,
      PopUpAlert: false,
    };
    this.props.playAudio("/Audio/Page_Start.mp3");
  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...props,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !Accessor.IsIdentical(
        prevProps,
        this.props,
        Object.keys(Start.defaultProps)
      )
    ) {
      this.componentDidMount();
    }
    if (this.props.HKIDAlert === true) {
      this.handleAlertOpen();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  setDoctor() {
    return;
  }

  nextPage() {
    this.props.changePageStatus("Question");
  }

  renderBottomBar() {
    if (this.state.voiceRecord) {
    } else {
      return (
        <button
          class="buttonReset StartNextButton"
          onClick={this.nextPage.bind(this)}
        ></button>
      );
    }
  }

  setVoiceRecordStatus(status) {
    this.setState({
      voiceRecord: status,
    });
  }

  handleAlertOpen = () => {
    this.setState({
      PopUpAlert: true,
    });
  };

  handleAlertClose = () => {
    this.setState({
      PopUpAlert: false,
    });
    this.props.setHKIDAlert(false);
  };

  render() {
    return (
      <div class="portal">
        <div class="HKUBackground">
          <div class="HKUWithDoctor">
            <MessageBox
              location="top"
              text="唔該晒！可以開始答問卷啦，記得收返好身份證！<br/>如果您有任何問題想問或者雙手唔方便，都可以用語音回答架！"
              lines="2"
            ></MessageBox>
            <Dialog
              className="Alert2"
              open={this.state.PopUpAlert}
              onClose={this.handleAlertClose}
              onBackdropClick={this.handleAlertClose}
            >
              <DialogContent>
                <DialogContentText>{"請取回身份證!"}</DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <Box className="QRDoctor">
              <img src={IdleDoctor} className="avatarIdleDoctor"></img>
            </Box>
          </div>
        </div>

        <div class="HKUButton">{this.renderBottomBar()}</div>
      </div>
    );
  }
}

export default Start;
