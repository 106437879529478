import React, { Component } from 'react';
import Accessor from '__Static/Accessor';
import PropsType from 'prop-types';
import { Box, CircularProgress, Typography, withStyles } from '@material-ui/core';
import MUIUtils from './_gears/MUIUtils';
import ColorX from '__Static/ColorX';

const styles = {
  root: {
    color: MUIUtils.getMappedProps(ColorX.GetColorCSS, "theme.bar"),
  }
};

/**
 * @property {{
 *  bar: Color
 * }} theme
 */
class StyledCircularProgress extends Component {

  static propTypes = {
    theme: PropsType.object,
    withLabel: PropsType.bool,
    value: PropsType.number
  }

  static defaultProps = {
    theme: {},
    withLabel: false,
    value: undefined
  }

  constructor(){
    super();
    this.state = {};
  }

  componentDidMount(){
    this._setAllStates();
  }

  componentDidUpdate(prevProps, prevState){
    if(!Accessor.IsIdentical(prevProps, this.props, Object.keys(StyledCircularProgress.defaultProps))){
      this._setAllStates();
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return;
    };
  }

  _setAllStates = (callback) => {
    this.setState((state, props) => ({
      ...props,
    }), callback);
  }

  render(){
    let {classes, theme, withLabel, value, ...other} = this.props;
    if(withLabel){
      return (
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={100}
            style={{color: theme.background || "transparent"}}
            />
          <CircularProgress 
            classes={classes}
            style={{position: "absolute"}}
            variant="determinate" 
            value={value}/>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography className={classes.root}>
              {Math.round(value) + '%'}</Typography>
          </Box>
        </Box>
      )
    }
    return (
      <Box>
        <CircularProgress
          variant="determinate"
          value={100}
        />
        <CircularProgress
          classes={classes}
          style={{position: "relative"}}
          value={value}
          {...other}
          />
      </Box>
      
    );
  }

}

export default withStyles(styles)(StyledCircularProgress);
