class ErrorX{

  static Handle(e){
    try{
      if(typeof (e.message) === "object"){
        if(e.message.Message){
          return JSON.stringify(e.message.Message);
        }else{ 
          return JSON.stringify(e.message);
        }
      }else{
        console.log(e);
        if(e.payload){
          return e.payload;
        }else{
          return e.message;
        }
      }
    }catch(e){
      return "Unidentified Error";
    }
  }

}

export default ErrorX;